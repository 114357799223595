import { LinkButton } from "../LinkButton/LinkButton"
import { Fragment } from "react"
import { Box } from "../Box/Box"
import "./style.css"
import { CurrentLink, NavbarLink } from "libs/src/admin/adminLib.types"
import { useLoadContext } from "libs"

export function Sidebar({
	navbarLink,
	clientId,
	currentLink,
}: {
	navbarLink: NavbarLink
	clientId?: string
	currentLink: CurrentLink
}) {
	const { user } = useLoadContext()
	return (
		<>
			<Box className="sidebar">
				{Object.values(navbarLink)
					.filter((page) => page.allowedUsers == null || page.allowedUsers.includes(user.role as "admin" | "support"))
					.map((page: any) => {
						return (
							<Fragment key={page.path}>
								<LinkButton
									className={`link-button-${page.title}`}
									path={page.path}
									current={currentLink}
									clientId={clientId}>
									{page.title}
								</LinkButton>
							</Fragment>
						)
					})}
			</Box>
		</>
	)
}
