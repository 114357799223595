import * as sortLib from "./sortLib"
import { sortDDMMYYYY } from "../dateLib/dateLib"

interface ObjectWithDates {
	endDate?: string
	startDate?: string
}

interface ObjectWithReceivedTime {
	receivedTime: number
}

interface ObjectWithDuplicationTime {
	duplicationTime: number
}

export function sortByEndDate(a: ObjectWithDates, b: ObjectWithDates): number {
	return sortDDMMYYYY(a.endDate, b.endDate)
}

export function sortByStartDate(a: ObjectWithDates, b: ObjectWithDates): number {
	return sortDDMMYYYY(a.startDate, b.startDate)
}

export function sortByDate(a: ObjectWithDates, b: ObjectWithDates): number {
	return sortLib.sortByEndDate(a, b) || sortLib.sortByStartDate(a, b)
}

export function getFirstWhenSorted<T>(array: T[], sortFunc: (a: T, b: T) => number): T {
	//Avoid sorting full array to find first
	function checkWhich(a: T, b: T): T {
		return sortFunc(a, b) <= 0 ? a : b
	}
	return array.reduce(checkWhich, array[0])
}

// export function sortByScoreForVersion(version) {
//     return function sortByScore(a, b) {
//         return ((b?.score?.[version] ?? 0) - (a?.score?.[version] ?? 0))
//     }
// }

export function sortByReceivedTime(a: ObjectWithReceivedTime, b: ObjectWithReceivedTime): number {
	return b.receivedTime - a.receivedTime
}

export function sortByDuplicated(a: ObjectWithDuplicationTime, b: ObjectWithDuplicationTime): number {
	return (b?.duplicationTime ?? 0) - (a?.duplicationTime ?? 0)
}

const baseAlphabeticalComparer = new Intl.Collator().compare

export const alphabeticalComparer = (a: string, b: string): number => baseAlphabeticalComparer(a?.trim?.(), b?.trim?.())
