import { useState, useEffect } from "react"
import Dropdown from "react-bootstrap/Dropdown"
import "./custom-dropdown.css"

export function CustomDropdown(props: {
	actions: { label: string; key: string | number }[]
	bestOption: string
	formId?: string
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	value: string
	setChangeTrigger?: (value: boolean) => void
}) {
	const { actions, bestOption, formId, value, setChangeTrigger } = props || {}
	const [selected, setSelected] = useState<null | string>()

	useEffect(() => {
		if (typeof value === "number") {
			setSelected(value)
		}
		const selectedAction = actions.find((action) => action.key === value)
		if (selectedAction) {
			setSelected(selectedAction.label)
		}
	}, [value])

	return (
		<Dropdown>
			<Dropdown.Toggle className="custom-dropdown-toggle">
				<span className="header-toggle">{selected ? selected : bestOption}</span>
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{actions.map((action, idx) => (
					<Dropdown.Item
						key={idx}
						onClick={() => {
							setSelected(action.label)
							props.onChange({
								target: {
									id: formId,
									value: action.key,
								},
							} as React.ChangeEvent<HTMLInputElement>)
							setChangeTrigger?.(true)
						}}>
						{action.label}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	)
}
