import {
	DEFAULT_BULLET_SYMBOL,
	DEFAULT_SUB_BULLET_SYMBOL,
	DEFAULT_BULLET_COLOR,
	DEFAULT_FONT_CASE,
	DEFAULT_BULLET_SPACING_LEFT,
	DEFAULT_BULLET_SPACING_RIGHT,
	DEFAULT_BASE_BULLET_SPACING_LEFT,
	DEFAULT_LINE_HEIGHT,
} from "../stringLib/stringLib"
import { getFontFamily } from "../fontLib/fontLib"

export function getBrandingStyles({ company }) {
	return {
		fontFamily: getFontFamily(company?.branding?.font, company),
		"--bullet-symbol": `"${company.branding?.bulletSymbol ?? DEFAULT_BULLET_SYMBOL}"`,
		"--bullet-color": company.branding?.bulletColor ?? DEFAULT_BULLET_COLOR,
		"--sub-bullet-symbol": `"${company.branding?.subBulletSymbol ?? DEFAULT_SUB_BULLET_SYMBOL}"`,
		"--sub-bullet-color": company.branding?.subBulletColor ?? company.branding?.bulletColor ?? DEFAULT_BULLET_COLOR,
		"--bullet-margin-left": `${company.branding?.bulletSpacingLeft ?? DEFAULT_BULLET_SPACING_LEFT}em`,
		"--base-bullet-margin-left": `${parseFloat(company.branding?.baseBulletSpacingLeft ?? DEFAULT_BASE_BULLET_SPACING_LEFT) + 0.5}em`,
		"--bullet-padding-left": `${company.branding?.bulletSpacingRight ?? DEFAULT_BULLET_SPACING_RIGHT}em`,
		"--local-line-height": company.branding?.lineHeight ?? DEFAULT_LINE_HEIGHT,
		...Object.entries(company.branding?.fontCase ?? []).reduce((acc, [name, value]) => {
			acc[`--${name}-font-case`] = value ?? DEFAULT_FONT_CASE
			return acc
		}, {}),
	}
}
