import {
	LoadingSpinner,
	NewStandardButton,
	PlainToggleSwitch,
	SubmitOnBlurInputField,
	SubmitOnSelectSelectField,
} from "components"
import { USER_DESCRIPTION_OPTIONS } from "containers"
import {
	adminPatchClientUser,
	adminUpdateClientUser,
	getHumanReadableAge,
	RESTRICTABLE_FEATURES,
	useFormFields,
	UserFromDb,
} from "libs"
import "./UserInfoView.css"
import { useCallback, useMemo, useState } from "react"

const CONTACT_TYPES_OPTIONS = ["SuperUser", "Champion", "Account lead", "Admin team"]

export function ExistingUserInfo({
	clientId,
	user,
	setUserData,
}: {
	clientId
	user: UserFromDb
	setUserData: (newData: UserFromDb) => void
}): JSX.Element {
	const [isLoading, setIsLoading] = useState(false)
	const [fields, handleFieldChange, updateFields] = useFormFields({
		linkedInUrl: user.linkedInUrl || "",
		contactType: user.contactType || "",
		userDescription: user.userDescription || "",
	})
	useMemo(() => {
		updateFields({
			linkedInUrl: user.linkedInUrl || "",
			contactType: user.contactType || "",
			userDescription: user.userDescription || "",
		})
	}, [user.userId])

	const handleBlurSubmit = (fieldName: string) => async (event: React.FocusEvent<HTMLInputElement>) => {
		await adminPatchClientUser({
			clientId: clientId,
			userId: user.userId,
			updates: { [fieldName]: fields[fieldName] },
		})
		setUserData({ ...user, [fieldName]: fields[fieldName] })
	}
	const handleSubmitOnSelect = async (fieldName: string) => {
		await adminPatchClientUser({
			clientId,
			userId: user.userId,
			updates: { [fieldName]: fields[fieldName] },
		})
		setUserData({ ...user, [fieldName]: fields[fieldName] })
	}
	const handleResendInvitation = async () => {
		setIsLoading(true)

		const response = await adminUpdateClientUser({
			clientId,
			userId: user.userId,
			updateType: "resendInvite",
		})
		setIsLoading(false)
		setUserData({ ...user, userId: response.userId })
	}
	return (
		<div className="user-info-view-scroll">
			<div className="user-info-section">
				<div>
					<span style={{ fontWeight: 500, width: "9rem", display: "inline-block", lineHeight: "39px" }}>Email:</span>
					<span>{user?.email}</span>
				</div>
				<div>
					<span style={{ fontWeight: 500, display: "inline-block", width: "9rem", lineHeight: "39px" }}>Role:</span>
					<span>{user?.userRole === "brandEditor" ? "brand editor" : user?.userRole}</span>
				</div>
				<div style={{ display: "flex", justifyContent: "left", alignItems: "center", gap: "4rem" }}>
					<div>
						<span style={{ fontWeight: 500, display: "inline-block", width: "9rem", lineHeight: "39px" }}>
							Last Login:
						</span>
						<span>{user?.lastLogin ? `${getHumanReadableAge(user?.lastLogin)} ago` : "Unknown"}</span>
					</div>

					{!user.lastLogin && (
						<NewStandardButton
							variant="outline"
							onClick={handleResendInvitation}
							style={isLoading ? { cursor: "not-allowed" } : {}}
							isLoading={isLoading}>
							Resend Invitation
						</NewStandardButton>
					)}
				</div>
				<SubmitOnBlurInputField
					key={"linkedInUrl"}
					label={"LinkedIn URL:"}
					type={"text"}
					placeholder={"Enter LinkedIn URL"}
					initialValue={fields.linkedInUrl}
					inputFieldHandler={handleFieldChange}
					fieldName={"linkedInUrl"}
					onBlur={handleBlurSubmit("linkedInUrl")}
				/>
				<SubmitOnSelectSelectField
					key={"userDescription"}
					label={"User Description:"}
					placeholder={fields.userDescription}
					fieldName={"userDescription"}
					handleSubmitOnSelect={handleSubmitOnSelect}
					field={fields.userDescription}
					updateField={updateFields}
					options={USER_DESCRIPTION_OPTIONS}
				/>
				<SubmitOnSelectSelectField
					key={"contactType"}
					label={"Contact Type:"}
					placeholder={fields.contactType}
					fieldName={"contactType"}
					handleSubmitOnSelect={handleSubmitOnSelect}
					field={fields.contactType}
					updateField={updateFields}
					options={CONTACT_TYPES_OPTIONS}
				/>
			</div>
			<div className="user-info-section">
				<h4>Access Management</h4>
				<div>Under construction</div>
			</div>
			<div>
				<h4>Beta Access</h4>
				<LabelledToggleSwitch
					label="Beta Site access (All Features)"
					value={user.userType === "beta"}
					onChange={async (newValue) => {
						const newData = await adminUpdateClientUser({
							clientId,
							userId: user.userId,
							betaAccess: newValue,
							updateType: "betaAccess",
						})
						setUserData({ ...newData, userId: user.userId })
					}}
				/>
				<UserBetaPermissions user={user} setUserData={setUserData} clientId={clientId} />
			</div>
		</div>
	)
}

function UserBetaPermissions({
	clientId,
	user,
	setUserData,
}: {
	clientId: string
	user: UserFromDb
	setUserData: (newData: UserFromDb) => void
}): JSX.Element {
	const onChange = useCallback(
		async (permissionKey, newValue) => {
			const newData = await adminPatchClientUser({
				clientId,
				userId: user.userId,
				updates: { betaPermissions: { ...user.betaPermissions, [permissionKey]: newValue } },
			})
			setUserData({ ...newData, userId: user.userId })
		},
		[user.betaPermissions, user.userId, setUserData]
	)

	return (
		<div className="user-info-section">
			<h5>Beta Feature Permissions (Access via Main Site)</h5>
			<div>
				{Object.entries(RESTRICTABLE_FEATURES)
					.filter(([, permission]) => permission.releaseState === "beta")
					.map(([permissionKey, permission]) => (
						<UserBetaToggle
							user={user}
							onChange={async (value) => {
								await onChange(permissionKey, value)
							}}
							permissionName={permissionKey}
							permissionLabel={permission.label}
							key={permissionKey}
						/>
					))}
			</div>
		</div>
	)
}

function UserBetaToggle({
	user,
	onChange,
	permissionName,
	permissionLabel,
}: {
	permissionName: string
	permissionLabel: string
	user: UserFromDb
	onChange: (value) => Promise<void>
}) {
	return (
		<LabelledToggleSwitch
			label={permissionLabel}
			value={user.betaPermissions?.[permissionName] ?? false}
			onChange={onChange}
		/>
	)
}

function LabelledToggleSwitch({
	label,
	value,
	onChange,
}: {
	label: string
	value: boolean
	onChange: (value) => Promise<void>
}) {
	const [updating, setUpdating] = useState(false)
	return (
		<div style={{ display: "flex", gap: 10, alignItems: "center", margin: "5px 0" }}>
			<span>{label}:</span>
			<div>
				<PlainToggleSwitch
					label={label}
					value={value ?? false}
					onChange={(newValue) => {
						setUpdating(true)
						onChange(newValue).finally(() => {
							setUpdating(false)
						})
					}}
				/>
			</div>
			<LoadingSpinner isLoading={updating} />
		</div>
	)
}
