import { deepCopy } from "./objLib"

/** combine at leaves. Receives a deeply nested object, combines lowest level 'leaves' into the reference object and returns the top level fields that have changed.
 * Special cases: an array of a different length should be treated like a leaf and be overwritten in the output
 * an array with more than one changed value should be treated like a leaf and be overwritten in the output
 * returns updated fields and the new reference object
 */
export function combineAtLeaves<T>(changes: Partial<T> | null, referenceObj: T): [T | null, Array<keyof T>] {
	if (changes === undefined) return [referenceObj, []]
	if (changes === null && referenceObj === null) return [null, []]
	if (changes === null) return [null, ["0" as keyof T]]
	if (Array.isArray(changes) && referenceObj == null) return [changes as T, ["0"] as Array<keyof T>]
	if (typeof changes === "object" && referenceObj == null)
		return [changes as T, (Object.keys(changes).length > 0 ? Object.keys(changes) : ["0"]) as Array<keyof T>]
	if (referenceObj == null) return [changes as T, ["0"] as Array<keyof T>]
	if (typeof changes !== typeof referenceObj) throw new Error("changes and referenceObj must be of the same type")
	if (Array.isArray(changes) && !Array.isArray(referenceObj))
		throw new Error("changes and referenceObj must be of the same type")
	if (!Array.isArray(changes) && Array.isArray(referenceObj))
		throw new Error("changes and referenceObj must be of the same type")
	if (typeof changes === "object" && !Array.isArray(changes) && Object.keys(changes)?.length === 0)
		return [referenceObj, []]
	if (typeof changes === "object" && Object.keys(referenceObj)?.length === 0)
		return [changes as T, Object.keys(changes) as Array<keyof T>]
	if (Array.isArray(changes) && Array.isArray(referenceObj)) {
		if (changes.length !== referenceObj.length) return [changes as T, ["0" as keyof T]]
		const updatedFields: Array<keyof T> = []
		const newReferenceObj = deepCopy(referenceObj)
		for (let i = 0; i < changes.length; i++) {
			const [newRef, updated] = combineAtLeaves(changes[i], newReferenceObj[i])
			if (updated.length > 0) {
				updatedFields.push(i as keyof T)
				newReferenceObj[i] = newRef
			}
		}
		return [newReferenceObj, updatedFields]
	}
	if (typeof changes === "object") {
		const updatedFields: Array<keyof T> = []
		const newReferenceObj = deepCopy(referenceObj)
		for (const key in changes) {
			const [newRef, updated] = combineAtLeaves(changes[key], newReferenceObj[key])
			if (updated.length > 0) {
				updatedFields.push(key)
				newReferenceObj[key] = newRef
			}
		}
		return [newReferenceObj, updatedFields]
	}
	return changes !== referenceObj ? [changes, ["0" as keyof T]] : [referenceObj, []]
}
