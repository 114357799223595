export const DEFAULT_CURRENT_TEXT = "Present"

export const DEFAULT_AVAILABLE_CUSTOM_TAGS = 5

export let COPY_AVAILABLE = false
// @ts-ignore: navigator permissions not yet in typescript
navigator.permissions?.query({ name: "clipboard-write" }).then((result) => {
	if (result.state === "granted") {
		COPY_AVAILABLE = true
	}
})

export const SUPPORTED_LANGUAGES = [
	"Arabic",
	"English (British)",
	"English (American)",
	"Chinese (Simplified)",
	"Chinese (Traditional)",
	"Czech",
	"Danish",
	"Dutch",
	"French",
	"German",
	"Italian",
	"Malay",
	"Norwegian",
	"Polish",
	"Portuguese",
	"Russian",
	"Slovak",
	"Spanish",
	"Swedish",
]
