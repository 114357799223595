import { useMountEffect, adminGetClientUsers, UserFromDb, Indexed, UserFromClient, colorByValue, useQuery } from "libs"
import { useCallback, useState } from "react"
import { LoaderButton, SearchBar, SortableTableCard, useSearch } from "components"
import { getHumanReadableAge, alphabeticalComparer, RESTRICTABLE_FEATURES } from "libs"
import { useHistory } from "react-router-dom"
import { UserInfoView } from "./UserInfoView"
import "./TeamPage.css"
import { AraTooltip } from "components/src/AraTooltip/AraTooltip"

interface UserStats {
	invited?: number
	signedIn?: number
	activeInLastMonth?: number
}

function lastLoggedInWithinLastMonth(lastLogin: string): boolean {
	const now = new Date().getTime()
	const oneMonthAgo = now / 1000 - 60 * 60 * 24 * 30
	return parseInt(lastLogin) > oneMonthAgo
}

function getUserStats(users: Indexed<UserFromDb>): UserStats {
	const stats: UserStats = {
		invited: 0,
		signedIn: 0,
		activeInLastMonth: 0,
	}
	const now = new Date().getTime()
	const oneMonthAgo = now / 1000 - 60 * 60 * 24 * 30
	Object.values(users).forEach((user) => {
		stats.invited += 1
		if (user.lastLogin != null) {
			stats.signedIn += 1
		}
		if (user.lastLogin != null && parseInt(user.lastLogin) > oneMonthAgo) {
			stats.activeInLastMonth += 1
		}
	})
	return stats
}

function getUsersFromClient(users: Indexed<UserFromClient>, clientId: string): Indexed<UserFromDb> {
	return Object.entries(users ?? {}).reduce((obj, [userId, user]) => {
		return {
			...obj,
			[userId]: {
				pk: `cli#${clientId}`,
				sk: `usr#${userId}`,
				miscData: `email#${user.email}`,
				userId,
				name: user.name,
				email: user.email,
				userRole: user.user_role,
				lastLogin: user.lastLogin,
			},
		}
	}, {})
}

export function TeamPage({ clientId, data }): JSX.Element {
	const history = useHistory()
	const query = useQuery()
	const [waiting, setWaiting] = useState<boolean>(false)
	let [users, setUsers] = useState<Indexed<UserFromDb>>({})
	const [userStats, setUserStats] = useState<UserStats>({})
	const clientUsers = getUsersFromClient(data.userlist, clientId) ?? {}
	const currentUser = query.get("userId")
	const newUserMode = query.get("newUserMode") === "true"
	const setCurrentUserData = useCallback((data) => {
		if (data == null) {
			return
		}
		setUsers((users) => ({ ...users, [data.userId ?? data.user_id]: data }))
		// setUserStats(getUserStats(users))
	}, [])

	const makeSearchString = useCallback((user: UserFromDb & { usageChange: number }) => {
		let base_search_string = `${user.name} ${user.email} ${user.userRole} ${user.contactType}}`
		Object.entries(SEARCH_TAGS).forEach(([key, { tag, test }]) => {
			if (test(user)) {
				base_search_string += ` ${tag}`
			}
		})
		return base_search_string
	}, [])

	const [searchTermRef, setSearchTerm, filteredUsers] = useSearch<UserFromDb>(users, makeSearchString, "team-table")

	const getUsers = () => {
		setWaiting(true)
		adminGetClientUsers({
			clientId,
			stats: ["cvsUploaded60", "cvsUploaded30", "cvsUploadedMonth"],
		})
			.then((newUsers) => {
				const indexedUsers = newUsers.reduce(
					(obj, user) => ({
						...obj,
						[user.userId]: { ...user, usageChange: user.cvsUploaded30 * 2 - user.cvsUploaded60 },
					}),
					{}
				)
				users = { ...clientUsers, ...indexedUsers }
				setUsers(users)
				setUserStats(getUserStats(users))
			})
			.finally(() => {
				setWaiting(false)
			})
	}

	useMountEffect(() => {
		// fetch users for client
		getUsers()
	})

	const headings = [
		"User Name",
		"Email",
		"Role",
		"CVs Processed (last 30 days)",
		"Usage Change",
		"CVs Processed (Calendar Month)",
		"Last Login",
	]

	const infoKeys = ["name", "email", "userRole", "cvsUploaded30", "usageChange", "cvsUploadedMonth", "lastLogin"]
	const preprocessing = [
		null,
		null,
		null,
		null,
		(a) => colorByValue(a, a > 0 ? `+${a}` : a, 0, false),
		null,
		preprocessLastLogin,
	]

	const individualSortFuncs = [
		(a, b) => -alphabeticalComparer(a.name, b.name),
		(a, b) => -alphabeticalComparer(a.email, b.email),
		(a, b) => -alphabeticalComparer(a.userRole, b.userRole),
		(a, b) => (b.cvsUploaded30 ?? 0) - (a.cvsUploaded30 ?? 0),
		(a, b) => (b.usageChange ?? 0) - (a.usageChange ?? 0),
		(a, b) => (b.cvsUploadedMonth ?? 0) - (a.cvsUploadedMonth ?? 0),
		(a, b) => (a.lastLogin ?? 0) - (b.lastLogin ?? 0),
	]

	const onClickNewUser = () => {
		history.replace({
			search: `newUserMode=true`,
		})
	}

	const showUserInfo = currentUser != null || newUserMode

	return (
		<div className={`account-container team-container ${showUserInfo ? "team-container-userview" : ""}`}>
			<h1>Team</h1>
			<div style={{ position: "absolute", right: 30, top: 20 }}>
				<AraTooltip
					className="admin-search-tooltip"
					placement="left"
					tooltipLabel={makeTooltipText(searchTermRef.current)}
					style={{ width: 400 }}>
					<SearchBar searchTermRef={searchTermRef} setSearchTerm={setSearchTerm} />
				</AraTooltip>
			</div>
			<div
				style={{
					display: "flex",
					gap: 15,
					justifyContent: "center",
					alignItems: "center",
					fontSize: 18,
					fontWeight: 500,
				}}>
				<div style={{ display: "flex", gap: 5 }}>
					<div
						style={{
							fontWeight: 300,
						}}>
						Active in last month:
					</div>
					<div>{userStats.activeInLastMonth}</div>
				</div>
				<div style={{ display: "flex", gap: 5 }}>
					<div
						style={{
							fontWeight: 300,
						}}>
						Signed In:
					</div>
					<div>{userStats.signedIn}</div>
				</div>
				<div style={{ display: "flex", gap: 5 }}>
					<div
						style={{
							fontWeight: 300,
						}}>
						Invited:
					</div>
					<div>{userStats.invited}</div>
				</div>
			</div>
			<div style={{ textAlign: "center" }}>(Sign in has been recorded since July 2023)</div>
			<LoaderButton
				onClick={onClickNewUser}
				className="SquarerLoaderButton"
				style={{ position: "absolute", zIndex: 1, left: 26, top: 12 }}>
				<span style={{ fontWeight: 400, color: "var(--ara-purple)" }}>+ Users</span>
			</LoaderButton>
			<SortableTableCard
				refreshProps={{ onClick: getUsers, isLoading: waiting }}
				info={Object.values(filteredUsers)}
				headings={showUserInfo ? [headings[0]] : headings}
				preprocessing={showUserInfo ? [preprocessing[0]] : preprocessing}
				infoKeys={showUserInfo ? [infoKeys[0]] : infoKeys}
				rowIdKey={"userId"}
				rowFunc={(userId) => {
					history.replace({
						search: `userId=${userId}`,
					})
				}}
				sortFunc={(a, b) => alphabeticalComparer(a.name, b.name)}
				individualSortFuncs={showUserInfo ? [individualSortFuncs[0]] : individualSortFuncs}>
				{showUserInfo && (
					<div className="user-info-container">
						<UserInfoView
							users={users}
							clientId={clientId}
							client={data}
							newUserMode={newUserMode}
							user={users[currentUser]}
							setUserData={setCurrentUserData}
						/>
					</div>
				)}
			</SortableTableCard>
		</div>
	)
}

function preprocessLastLogin(unixTimestamp) {
	const loggedInThisMonth = lastLoggedInWithinLastMonth(unixTimestamp)
	const readableDate = getHumanReadableAge(unixTimestamp)
	return <span className={loggedInThisMonth ? "greenText" : "redText"}>{readableDate}</span>
}

const BETA_SEARCH_TAGS: {
	[key: string]: { tag: string; description: string; test: (user: UserFromDb & { usageChange: number }) => boolean }
} = Object.entries(RESTRICTABLE_FEATURES).reduce((obj, [key, value]) => {
	if (value.releaseState !== "beta") {
		return obj
	}
	return {
		...obj,
		[key]: {
			tag: `@beta:${key}`,
			description: `Beta access to ${value.label}`,
			test: (user) => user.betaPermissions?.[key] ?? false,
		},
	}
}, {})

const SEARCH_TAGS: {
	[key: string]: { tag: string; description: string; test: (user: UserFromDb & { usageChange: number }) => boolean }
} = {
	...BETA_SEARCH_TAGS,
	betaSite: {
		tag: "@beta:siteAccess",
		description: "Beta Site Access",
		test: (user) => user.userType === "beta",
	},
	usageChangeLessThan0: {
		tag: "@uc<0",
		description: "Usage going down in last month",
		test: (user) => user.usageChange < 0,
	},
	invitationUnaccepted: {
		tag: "@ui",
		description: "Unaccepted Invitation",
		test: (user) => user.lastLogin == null,
	},
	lastLoginOverAMonthAgo: {
		tag: "@ll>30",
		description: "Last login over a month ago",
		test: (user) =>
			user.lastLogin != null && parseInt(user.lastLogin) < new Date().getTime() / 1000 - 60 * 60 * 24 * 30,
	},
	lastLoginOver2MonthsAgo: {
		tag: "@ll>60",
		description: "Last login over 2 months ago",
		test: (user) =>
			user.lastLogin != null && parseInt(user.lastLogin) < new Date().getTime() / 1000 - 60 * 60 * 24 * 60,
	},
}

function makeTooltipText(search) {
	return (
		<div>
			{Object.values(SEARCH_TAGS).map(({ tag, description }) => (
				<div style={{ display: "flex", color: tag.includes(search) ? "white" : "#aaaaaa" }} key={tag}>
					<div style={{ width: 200 }}>{tag}:</div>
					<div>{description}</div>
				</div>
			))}
		</div>
	)
}
