import { CardView } from "../CardView/CardView"
import "./TableView.css"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { RefreshButton } from "../RefreshButton/RefreshButton"
import { useCallback, useState, memo, useMemo, MouseEventHandler, MouseEvent } from "react"
import arrow from "../../images/collapse-arrow.png"
import { useSelectionContext, SelectorInput } from "../SelectionContext/SelectionContext"
import { AraTooltip } from "../AraTooltip/AraTooltip"

const rowPropsAreEqual = (prevProps: TableRowProps, nextProps: TableRowProps) => {
	const { info: prevInfo, ...otherPrevProps } = prevProps
	const { info: nextInfo, ...otherNextProps } = nextProps
	for (var infoKey of Object.keys(nextInfo)) {
		if (nextInfo[infoKey] !== prevInfo[infoKey]) {
			return false
		}
	}
	for (var key of Object.keys(otherNextProps)) {
		if (otherNextProps[key] !== otherPrevProps[key]) {
			return false
		}
	}
	return true
}

interface TableRowProps {
	info: any
	useSelection?: boolean
	infoKeys: string[]
	sizes?: number[]
	rowId: string
	rowFunc: (rowId: string) => void
	preprocessing?: ((a: any) => any)[]
	handleOnClick?: (e: any) => void
	className?: string
	highlighted?: boolean
}

const TableRow = memo<TableRowProps>(
	({ info, useSelection, infoKeys, sizes, rowId, rowFunc, preprocessing, handleOnClick, highlighted, ...props }) => {
		return (
			<Row
				{...props}
				onClick={() => {
					rowFunc?.(rowId)
				}}
				className={`tableRow flex-nowrap ${rowFunc == null ? "" : "clickable"} standardPadding ${props.className} ${
					highlighted ? "highlighted-table-row" : ""
				}`}>
				{<RowSelector rowId={rowId} showSelectedCount={false} />}
				{infoKeys.map((infoKey, index) => (
					<TableCol key={rowId + infoKey} {...{ info, infoKey, rowId, sizes, index, preprocessing }} />
				))}
			</Row>
		)
	},
	rowPropsAreEqual
)

const MAX_PER_PAGE = 30

interface SortableTableProps {
	sortFunc?: SortFunc
	headings: string[]
	info: any[]
	infoKeys: string[]
	highlighted?: string[]
	rowIdKey: string
	rowFunc?: (rowId: string) => void
	preprocessing?: ((a: any, row: string) => any)[]
	sizes?: number[]
	individualSortFuncs?: SortFunc[]
	noInfoData?: { text: string; children: React.ReactNode }
	pageNumber?: number
	setPageNumber?: (newNumber: number) => void
	summaryRow?: Array<(info) => React.ReactNode>
}

interface SortableTableCardProps extends SortableTableProps {
	title?: string
	refreshProps?: { onClick: (e: Event) => void; isLoading: boolean }
	children?: React.ReactNode
}

interface CurrentSortData {
	index: number
	direction: 1 | -1
}

export type SortFunc<T = any> = (a: T, b: T) => number

function useTableSorting(defaultSortFunc: SortFunc | null, individualSortFuncs: SortFunc[]) {
	const [currentSortData, setCurrentSortData] = useState<CurrentSortData>(null)
	const [currentSortFunc, setCurrentSortFunc] = useState<SortFunc | null>(() => defaultSortFunc)

	const sortFunctions: Array<[MouseEventHandler<HTMLElement> | null, MouseEventHandler<HTMLElement> | null]> = useMemo(
		() =>
			individualSortFuncs?.map((sortFunc, index) => {
				const setFunc: MouseEventHandler<HTMLElement> = sortFunc
					? (e: MouseEvent<HTMLElement>) => {
							if (currentSortData?.index === index && currentSortData?.direction === 1) {
								console.log("set back to default")
								setCurrentSortData({ index: null, direction: null })
								setCurrentSortFunc(() => defaultSortFunc)
							} else {
								setCurrentSortData({ index: index, direction: 1 })
								setCurrentSortFunc(() => sortFunc)
							}
						}
					: null
				const setReversedFunc = sortFunc
					? (e: MouseEvent<HTMLElement>) => {
							if (currentSortData?.index === index && currentSortData?.direction === -1) {
								console.log("set back to default")
								setCurrentSortData({ index: null, direction: null })
								setCurrentSortFunc(() => defaultSortFunc)
							} else {
								setCurrentSortData({ index: index, direction: -1 })
								setCurrentSortFunc(() => (a, b) => -sortFunc(a, b))
							}
						}
					: null
				return [setFunc, setReversedFunc]
			}) ?? [],
		[currentSortData?.direction, currentSortData?.index, defaultSortFunc, individualSortFuncs]
	)

	return { currentSortFunc, sortFunctions, currentSortData }
}

export function SortableTableCard({ title = null, refreshProps, children, ...props }: SortableTableCardProps) {
	return (
		<CardView className="tableView">
			{refreshProps !== undefined && <RefreshButton {...refreshProps} />}
			{title ? <h4 className="table-title">{title}</h4> : <></>}
			<SortableTable {...props}></SortableTable>
			{children}
		</CardView>
	)
}

export function SortableTable({
	sortFunc,
	headings,
	info,
	infoKeys,
	rowIdKey,
	rowFunc,
	preprocessing,
	sizes,
	highlighted,
	individualSortFuncs,
	noInfoData = { text: "Nothing to show", children: "" },
	pageNumber = 0,
	setPageNumber = null,
	summaryRow = null,
}: SortableTableProps) {
	const adjustedSizes = sizes ? makeSizes(sizes) : null
	const { currentSortFunc, currentSortData, sortFunctions } = useTableSorting(sortFunc, individualSortFuncs)
	info = useMemo(() => (currentSortFunc != null ? info.sort(currentSortFunc) : info), [currentSortFunc, info])
	const [pageNumberInternal, setPageNumberInternalBase] = useState(pageNumber)
	// console.log(info.length)

	useMemo(() => {
		setPageNumberInternalBase(pageNumber)
	}, [pageNumber])

	const setPageNumberInternal = useCallback(
		(newNumber) => {
			setPageNumberInternalBase(newNumber)
			setPageNumber?.(newNumber)
		},
		[setPageNumber]
	)
	return (
		<Row className="tableScrollContent" role="table">
			<Col className="tableContent">
				<TitleRow
					headings={headings}
					sizes={adjustedSizes}
					currentSortData={currentSortData}
					sortFunctions={sortFunctions}
				/>

				{info.length > 0 ? (
					<>
						{summaryRow && (
							<SummaryRow
								summaryRow={summaryRow}
								info={info}
								adjustedSizes={adjustedSizes}
								rowProps={{ className: "summary-row" }}
								noCols={infoKeys?.length}
								preprocessing={preprocessing}
							/>
						)}
						{info
							.slice(pageNumberInternal * MAX_PER_PAGE, (pageNumberInternal + 1) * MAX_PER_PAGE)
							.map((rowInfo, index) => {
								const {
									rowProps,
									[rowIdKey]: rowId = "empty_row_id" + Math.random().toString(36).substring(7),
									...otherInfo
								} = rowInfo
								return (
									<TableRow
										role="row"
										key={rowId}
										{...(rowProps ?? {})}
										info={otherInfo}
										infoKeys={infoKeys}
										preprocessing={preprocessing}
										rowId={rowId}
										rowFunc={rowFunc}
										sizes={adjustedSizes}
										highlighted={highlighted?.includes(rowId)}
									/>
								)
							})}
						<Pager pageNumber={pageNumberInternal} setPageNumber={setPageNumberInternal} total={info.length} />
					</>
				) : (
					<div className="noInfoPlaceholder">
						{noInfoData.text} {noInfoData.children}
					</div>
				)}
			</Col>
		</Row>
	)
}

function SummaryRow({ summaryRow, adjustedSizes, info, rowProps, noCols, preprocessing }) {
	const summaryInfo = summaryRow.map((rowFunc) => rowFunc?.(info))
	const infoKeys = new Array(noCols).fill(0).map((_, i) => i)

	return (
		<TableRow
			role="row"
			{...(rowProps ?? {})}
			info={summaryInfo}
			infoKeys={infoKeys}
			rowId={"summary_row"}
			sizes={adjustedSizes}
			preprocessing={preprocessing}
		/>
	)
}

function Pager({
	pageNumber,
	setPageNumber,
	total,
}: {
	pageNumber: number
	setPageNumber: (newNumber: number) => void
	total: number
}) {
	return (
		total > MAX_PER_PAGE && (
			<div className="pager">
				<div className="pager-back">
					{pageNumber > 0 && (
						<img
							className="clickable"
							onClick={() => {
								setPageNumber(pageNumber - 1)
							}}
							src={arrow}
							alt="back"></img>
					)}
				</div>
				<div className="pager-number">{`${pageNumber * MAX_PER_PAGE + 1} - ${Math.min(
					(pageNumber + 1) * MAX_PER_PAGE,
					total
				)}`}</div>
				<div className="pager-forward">
					{(pageNumber + 1) * MAX_PER_PAGE < total && (
						<img
							className="clickable"
							onClick={() => {
								setPageNumber(pageNumber + 1)
							}}
							src={arrow}
							alt="forward"></img>
					)}
				</div>
			</div>
		)
	)
}

// function tryOptions(info, infoKey) {
//     //If infoKey is an Array sohuld try each in order before leaving blank
//     if (Array.isArray(infoKey)) {
//         for (var i = 0; i < infoKey.length; i++) {
//             if (info[infoKey[i]] !== undefined) {
//                 return info[infoKey[i]]
//             } else {
//                 continue
//             }
//         }
//     }
//     else {
//         return info[infoKey]
//     }

// }

function TitleRow({
	headings,
	sizes,
	currentSortData,
	sortFunctions,
}: {
	headings: string[]
	sizes?: number[]
	currentSortData: CurrentSortData
	sortFunctions: Array<[MouseEventHandler<HTMLElement> | null, MouseEventHandler<HTMLElement> | null]>
}) {
	return (
		<Row className="titleRow flex-nowrap standardPadding">
			{<RowSelector rowId={"all"} showSelectedCount={true} />}
			{headings?.map((heading, index) => (
				<Col
					style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
					className="titleRowCol"
					xs={sizes?.[index]}
					key={heading}>
					{heading}
					{(sortFunctions?.[index]?.[0] || sortFunctions?.[index]?.[1]) && (
						<div>
							<AraTooltip tooltipLabel={`Sort ${heading} Descending`}>
								<div
									className="icon-up-arrow clickable"
									style={{
										width: 5.5,
										margin: "5px 3px",
										borderBottomColor:
											currentSortData?.index === index && currentSortData?.direction === 1 ? "black" : "#aaaaaa",
									}}
									onClick={sortFunctions[index][0]}></div>
							</AraTooltip>
							<AraTooltip tooltipLabel={`Sort ${heading} Ascending`}>
								<div
									className="icon-down-arrow clickable"
									style={{
										width: 5.5,
										margin: "5px 3px",
										borderTopColor:
											currentSortData?.index === index && currentSortData?.direction === -1 ? "black" : "#aaaaaa",
									}}
									onClick={sortFunctions[index][1]}></div>
							</AraTooltip>
						</div>
					)}
				</Col>
			))}
		</Row>
	)
}

interface TableColProps {
	info: any
	infoKey: string
	sizes?: number[]
	rowId: string
	index: number
	preprocessing?: ((a: any) => any)[]
}

function TableCol({ info, infoKey, sizes, rowId, index, preprocessing, ...props }: TableColProps) {
	const preprocess = (preprocessing ? preprocessing[index] : null) || ((a, b) => a)
	const data = info[infoKey]
	return (
		<Col xs={sizes?.[index]} {...props}>
			{data !== undefined ? preprocess(data, rowId) : "-"}
		</Col>
	)
}

function makeSizes(sizes: number[], multiplier = 12) {
	const total = sizes.reduce(function (a, b) {
		return a + b
	}, 0)
	return sizes.map((size) => (multiplier * size) / total)
}

function RowSelector({ rowId, showSelectedCount = false }: { rowId: string; showSelectedCount?: boolean }) {
	const {
		currentSelection: { [rowId]: isSelected },
		changeSelectionForId,
		selectedCount,
	} = useSelectionContext()
	return (
		selectedCount != null && (
			<div
				className="selection"
				onClick={(e) => {
					e.stopPropagation()
				}}>
				<SelectorInput rowId={rowId} isSelected={isSelected} changeSelectionForId={changeSelectionForId} />
				{showSelectedCount === true && (
					<div className="selection-count">{selectedCount > 0 ? `${selectedCount} selected` : " "}</div>
				)}
			</div>
		)
	)
}
