import { forwardRef } from "react"
import "./box.css"

export const Box = forwardRef(
	(
		{
			className,
			children,
			...props
		}: {
			className?: string
			children?: React.ReactNode
		},
		ref: React.Ref<HTMLDivElement>
	) => {
		return (
			<div ref={ref} className={`${className || ""} flex-box`} {...props}>
				{children}
			</div>
		)
	}
)
