import { AccountForm } from "./AccountForm"

export function AccountPage({ data, clientId, setData }): JSX.Element {
	return (
		<div className="account-container">
			<h1>Account</h1>
			<AccountForm data={data} clientId={clientId} setData={setData} />
		</div>
	)
}
