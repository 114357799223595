import { useState, useEffect, useMemo } from "react"
import { useAppContext, useConfigContext, UserFromClient, UserFromDb } from "libs"
import { CenteredLoaderButton } from "components"
import { useFormFields, useMountEffect, getHumanReadableAge } from "libs"
import { JobFormField, DropdownFormField } from "components"
import { validateEmail } from "libs"
import { patchUser, createUser, deleteUser, getUsers } from "libs"
import { capitalizeString } from "libs"
import { AdminText } from "./AdminText"
import { getUserRole } from "./getUserRole"

export function TeamTab({ colWidths }) {
	const dropDownWidth = 5
	const { defaultUserRole = "recruiter", userRoles, userType } = useConfigContext()
	const { company, setCompany, user } = useAppContext()
	const [roleChangeLoadingFlags, setRoleChangeLoadingFlags] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [inviteFields, handleInviteFields] = useFormFields({
		name: "",
		email: "",
	})
	const [directDbUsers, setDirectDbUsers] = useState<{ [userId: string]: UserFromDb }>({})

	useMountEffect(() => {
		getUsers({ clientId: company.id }).then((users) => {
			setDirectDbUsers(
				users.reduce((indexedUsers, user) => {
					indexedUsers[user.sk.slice(4)] = user
					return indexedUsers
				}, {})
			)
		})
	})

	const users: { [userId: string]: UserFromDb | UserFromClient } = useMemo(
		() => ({ ...company.team, ...directDbUsers }),
		[company.team, directDbUsers]
	)

	useEffect(() => {
		const numberOfTeamMembers = Object.keys(company?.team ?? {}).length
		setRoleChangeLoadingFlags(Array(numberOfTeamMembers).fill(false))
	}, [company.team])

	async function onSendInvite(event) {
		event.preventDefault()
		setIsLoading(true)
		try {
			await createUser({
				userEmail: inviteFields.email,
				userName: inviteFields.name,
				userRole: defaultUserRole,
				companyId: company.id,
				company: company.name,
				userType,
			})

			setCompany({
				...company, //triggers re-fetch so no need to add new user and risk duplicating
			})
		} catch (e) {
			if (e.message.includes("500")) {
				alert("Unable to add user, this may occur when they already have an account.")
			} else if (e.message.includes("403")) {
				alert("Failed to add user (Are you an admin?)")
			}
		}
		setIsLoading(false)
	}

	function makeOptions(userId, currentRole, idx) {
		const possibleRoles = Array.from(userRoles ?? ["admin", "recruiter", "brand editor"])
		const roleIndex = possibleRoles.indexOf(currentRole)
		if (roleIndex !== -1) {
			possibleRoles.splice(roleIndex, 1)
		}
		return possibleRoles
			.map((role) => ({ text: "Change to " + capitalizeString(role), onClick: makeChangeRoleClick(userId, role, idx) }))
			.concat({ text: "Delete", onClick: makeDeleteClick(userId, idx) })
	}

	function validateInviteForm() {
		return inviteFields.name.length > 0 && validateEmail(inviteFields.email)
	}

	function makeDeleteClick(userId, idx) {
		return async function (event) {
			event.preventDefault()
			//set loading
			roleChangeLoadingFlags[idx] = true
			setRoleChangeLoadingFlags([...roleChangeLoadingFlags])
			//Make change
			await deleteUserWithChecks(userId)
			//set not loading
			roleChangeLoadingFlags[idx] = false
			setRoleChangeLoadingFlags([...roleChangeLoadingFlags])
		}
	}

	async function deleteUserWithChecks(userId) {
		if (user.role === "admin") {
			if (userId === user.id) {
				alert("An admin cannot delete themselves")
				return
			}
			await deleteUser({ userId, companyId: company.id, company: company.name })
			delete company.team[userId]
			setCompany({ ...company })
		} else {
			alert("Only an admin may delete a user")
		}
	}

	function makeChangeRoleClick(changingUser, newRole, idx) {
		return async function onClick(event) {
			event.preventDefault()
			//set loading
			roleChangeLoadingFlags[idx] = true
			setRoleChangeLoadingFlags([...roleChangeLoadingFlags])
			//Make change
			await changeRole(changingUser, newRole)
			//set not loading
			roleChangeLoadingFlags[idx] = false
			setRoleChangeLoadingFlags([...roleChangeLoadingFlags])
		}
	}

	async function changeRole(changingUser, newRole) {
		if (user.role === "admin") {
			if (changingUser === user.id) {
				alert("An admin cannot remove their own admin privileges")
				return
			}
			await patchUser({
				userId: changingUser,
				email: users[changingUser]?.email,
				userRole: newRole === "brand editor" ? "brandEditor" : newRole,
				clientId: company.id,
				clientName: company.name,
			})
			const newCompany = {
				...company,
				...{
					team: {
						...company.team,
						...{ [changingUser]: { name: users[changingUser].name, user_role: newRole } },
					},
				},
			}
			setCompany(newCompany)
		} else {
			alert("Only an admin may change a user's role")
		}
	}

	return (
		<>
			<AdminText>Invite new user:</AdminText>
			<form id="invite-new-user-form" onSubmit={onSendInvite}>
				<JobFormField
					tooltipProps={{
						tooltipLabel: "Name is required for invitation",
						holdOn: !inviteFields.name && inviteFields.email?.length > 0,
						highlight: !inviteFields.name && inviteFields.email?.length > 0,
					}}
					label="Name:"
					field={inviteFields.name}
					fieldHandler={handleInviteFields}
					controlId="name"
					type="name"
					colWidths={colWidths}
					autoFocus={true}></JobFormField>
				<JobFormField
					tooltipProps={{
						tooltipLabel: "Email address must be valid",
						holdOn: inviteFields.email?.length > 0 && !validateEmail(inviteFields.email),
						highlight: inviteFields.email?.length > 0 && !validateEmail(inviteFields.email),
					}}
					label="Email:"
					field={inviteFields.email}
					fieldHandler={handleInviteFields}
					controlId="email"
					type="email"
					colWidths={colWidths}></JobFormField>
				<CenteredLoaderButton
					variant="aradark"
					type="submit"
					size="large"
					isLoading={isLoading}
					disabled={!validateInviteForm()}>
					Send Invitation
				</CenteredLoaderButton>
			</form>
			<AdminText>Current Users:</AdminText>
			<div>
				{Object.entries(users).map(([userId, userInfo], idx) => {
					return (
						<DropdownFormField
							key={userId}
							label={userInfo.name + ":"}
							isLoading={roleChangeLoadingFlags[idx]}
							controlId="userRole"
							field={capitalizeString(getUserRole(userInfo))}
							header="Actions:"
							options={makeOptions(userId, getUserRole(userInfo), idx)}
							colWidths={[colWidths[0], "auto", 12 - dropDownWidth - colWidths[0]]}
							rhs={
								<div style={{ fontSize: 10 }}>
									{userInfo.lastLogin == null
										? ""
										: `Last login: ${getHumanReadableAge(parseInt(userInfo.lastLogin), 1)} ago`}
								</div>
							}></DropdownFormField>
					)
				})}
			</div>
		</>
	)
}
