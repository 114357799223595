import { ReactNode } from "react"

const statusMapping = {
	processed: "Processed",
	unprocessed: "Processing",
	generatingTags: "Generating AI Content",
}

export function getProcessingStatus({ miscData }, defaultReturn = null) {
	if (!miscData) {
		return defaultReturn
	}
	const status = miscData.split("#")[1]
	return statusMapping[status] ?? defaultReturn
}

export function bytesToMegabytes(bytes, decimalPlaces = 1) {
	return (bytes / 1024 / 1024).toFixed(decimalPlaces)
}

export function colorByValue(value: number, label: ReactNode, threshold = 100, greaterThan = true) {
	return <div style={{ color: value > threshold === greaterThan ? "var(--ara-red)" : "var(--ara-green)" }}>{label}</div>
}
