import { fieldSet } from "libs"

/* Exporting a jsx const called utils.  */
export const utils = {
	toTitleCase(str: string) {
		if (!str) {
			return null
		}
		return <span style={{ textTransform: "capitalize" }}>{str}</span>
	},
	getStatusColor(status: string, freeTrialEndData: string) {
		if (!status) {
			return null
		}
		const item = fieldSet.miscData.find((item) => item.key === status)
		// count how many days left in free trial period
		const freeTrialEnd = freeTrialEndData
			? ` (${Math.ceil(((new Date(freeTrialEndData) as unknown as number) - Date.now()) / 86400000)} days)`
			: ""

		if (item) {
			if (item.key === "free_trial") {
				return (
					<span
						style={{
							color: item.color,
							textTransform: "capitalize",
						}}>
						{item.label}
						{freeTrialEnd}
					</span>
				)
			}
			return (
				<span
					style={{
						color: item.color,
						textTransform: "capitalize",
					}}>
					{item.label}
				</span>
			)
		}
	},
}
