import { LoadingSpinner, CardView } from "components"

export function VolumeCard({ number, label, fetching = false }) {
	return (
		<CardView className="VolumeCard">
			<div className="VolumeNumber">{fetching ? <LoadingSpinner isLoading={fetching} /> : number}</div>
			<div className="volumeLabel">{label}</div>
		</CardView>
	)
}
