import { useState } from "react"
import Form from "react-bootstrap/Form"
import { LoadingSpinner } from "../LoaderButton/LoaderButton"
import { FormField } from "./FormField"
import "./JobFormField.css"

export function AutoEntryFormField({
	label,
	autoFocus = false,
	controlId,
	field,
	fieldHandler,
	buttonFlag = false,
	key = null,
	buttonPress = null,
	type = "name",
	submitFunc,
	colWidths = [5, 5, 2],
	...props
}) {
	const [isLoading, setLoading] = useState(false)
	const control = (
		<Form.Control
			type={type}
			autoFocus={autoFocus}
			value={key === null ? field : field[key] || ""}
			onChange={fieldHandler}
			onBlur={async () => {
				setLoading(true)
				await submitFunc(field)
				setLoading(false)
			}}
			{...props}
		/>
	)
	const rhs = <LoadingSpinner isLoading={isLoading} />
	return (
		<FormField label={label} controlId={controlId} rhs={rhs} keyValue={key} colWidths={colWidths} {...props}>
			{control}
		</FormField>
	)
}
