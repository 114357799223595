import "./ExpandCollapse.css"
import collapseArrow from "../../images/collapse-arrow.png"
import { useStickyState } from "libs"

export function ExpandCollapse({ title, initShow = true, children, className = "" }) {
	const [show, setShow] = useStickyState({ initState: initShow, storageName: `${title}-ec-section` })

	const onClick = () => {
		setShow(!show)
	}

	return (
		<div data-testid={title}>
			<div
				onClick={onClick}
				className={`${className} expand-title expand-title-${show ? "expanded" : "collapsed"} clickable`}>
				{title}
				<img alt="collapseArrow" src={collapseArrow} className="ec-arrow"></img>
			</div>
			{show && children}
		</div>
	)
}
