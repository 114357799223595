import { LoaderButton } from "components"
import { adminDeleteClient, addStickyValue } from "libs"
import { useState } from "react"
import { useHistory } from "react-router-dom"

export function DeletePage({ clientId, data, setData }): JSX.Element {
	const history = useHistory()
	const [isLoading, setIsLoading] = useState<boolean>(false)

	return (
		<div className="account-container">
			<h1>Delete Account</h1>
			{/* <h4>Switch Options</h4> */}
			<div
				style={{
					minHeight: 200,
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					marginBottom: 20,
				}}>
				<div style={{ margin: 15 }}>Delete entire account including all data and client details?</div>
				<LoaderButton
					className="SquarerLoaderButton"
					onClick={() => {
						if (window.confirm(`Deleting: \n${data.name}\n\nThis is irreversible. Are you sure?`)) {
							setIsLoading(true)
							adminDeleteClient({ clientId }).then(() => {
								addStickyValue({
									// set a different tab to default after deletion to avoid deletion page opening more often than needed
									storageName: "clientDetailsTab",
									value: "account",
									sessionOnly: false,
									userId: "admin",
								})
								history.push("/")
							})
						}
					}}
					isLoading={isLoading}>
					Delete Entire Account
				</LoaderButton>
			</div>
		</div>
	)
}
