import { useState, useRef } from "react"
import {
	LoginFormField,
	CenteredLoaderButton,
	CaptchaElement,
	MFASetup,
	MFAValidate,
	SignUpForm,
	SignUpCardWithImage,
} from "components"
import "./../Login.css"
// import { useLogin } from "libs";
import { Link } from "react-router-dom"
import { onError, useLogin } from "libs"
import { useFormFields, useLoadContext } from "libs"
import Auth from "@aws-amplify/auth"
import ReCAPTCHA from "react-google-recaptcha"

export function MFALogin() {
	const [currentForm, setCurrentForm] = useState("startLogin")
	const performLogin = useLogin({ responseOnly: false })
	const captchaRef = useRef<ReCAPTCHA>(null)
	const mostRecentSigninResponse = useRef(null)
	const validationRef = useRef(null)
	const [fields, handleFieldChange] = useFormFields({
		email: "",
		password: "",
		code: "",
	})
	const [isLoading, setIsLoading] = useState(false)
	const { setUser } = useLoadContext()
	const mfaConfirmed = useRef(false)

	function validateForm() {
		return fields.email.length > 0 && fields.password.length > 0
	}

	function handleSubmit(event) {
		event.preventDefault()
		setIsLoading(true)
		captchaRef.current.execute()
	}

	async function signin() {
		mostRecentSigninResponse.current = await Auth.signIn(fields.email, fields.password, validationRef.current)
	}

	async function attemptSignin() {
		console.log("attmepting signin")
		await signin()
		console.log("challenge", mostRecentSigninResponse.current.challengeName)
		if (mostRecentSigninResponse.current.challengeName == null || mfaConfirmed.current) {
			// setUser(makeUser(mostRecentSigninResponse.current, {}))
			await performLogin({ email: fields.email, password: fields.password, validationData: validationRef.current })
		}
		if (mostRecentSigninResponse.current.challengeName === "MFA_SETUP") {
			setCurrentForm("setupMFA")
			return
		}
		if (mostRecentSigninResponse.current.challengeName === "SOFTWARE_TOKEN_MFA") {
			setCurrentForm("enterMFA")
			return
		}
	}

	async function onCaptchaChange(validationData) {
		try {
			validationRef.current = validationData
			await attemptSignin()
		} catch (e) {
			setIsLoading(false)
			captchaRef.current.reset()
			onError(e)
		}
	}

	function renderMFASetup() {
		return (
			<MFASetup
				signinResponse={mostRecentSigninResponse.current}
				handleFieldChange={handleFieldChange}
				fields={fields}
				onSubmit={attemptSignin}
				mfaRef={mfaConfirmed}
				sessionOverrideRef={undefined}
			/>
		)
	}

	function renderMFA() {
		return (
			<MFAValidate
				signinResponse={mostRecentSigninResponse.current}
				handleFieldChange={handleFieldChange}
				fields={fields}
				onSubmit={attemptSignin}
				mfaRef={mfaConfirmed}
			/>
		)
	}

	function renderStartLogin() {
		return (
			<SignUpCardWithImage size="medium">
				<div className="login-form-container">
					<SignUpForm
						title={
							<>
								<h1>Welcome Back!</h1>
								<h2>Please enter your details</h2>
							</>
						}
						button={{
							text: "Login",
							role: "submit",
							onClick: handleSubmit,
							disabled: !validateForm(),
							isLoading: isLoading,
						}}
						fields={[
							{
								label: "Email",
								type: "email",
								placeholder: "Enter email",
								fieldName: "email",
								initialValue: fields.email,
								autofocus: true,
								autocomplete: "email",
								required: true,
								inputFieldHandler: handleFieldChange,
							},

							{
								label: "Password",
								type: "password",
								placeholder: "Enter password",
								fieldName: "password",
								autocomplete: "current-password",
								required: true,
								initialValue: fields.password,
								inputFieldHandler: handleFieldChange,
								description: <Link to="/forgotpassword">Forgot password?</Link>,
							},
						]}
					/>
					<div>
						<CaptchaElement ref={captchaRef} onChange={onCaptchaChange} shouldExecuteAfterErrorReset={isLoading} />
					</div>
				</div>
			</SignUpCardWithImage>
		)
	}

	function renderForm() {
		switch (currentForm) {
			case "changePassword": {
				return renderStartLogin()
			}
			case "setupMFA": {
				return renderMFASetup()
			}
			case "enterMFA": {
				return renderMFA()
			}
			default: {
				return renderStartLogin()
			}
		}
	}

	return <div className="Login">{renderForm()}</div>
}
