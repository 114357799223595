import { useState } from "react"
import { useLoadContext } from "libs"
import { useMountEffect } from "libs"
import { DropdownFormField } from "components"
import { updateFolder, TwoWayMap } from "libs"

const expirationMapping = new TwoWayMap({
	2: "2 days",
	7: "1 week",
	14: "2 weeks",
	21: "3 weeks",
	28: "1 month",
	90: "3 months",
	180: "6 months",
	365: "1 year",
	730: "2 years",
})

export function DataTab() {
	const dropDownWidth = 4
	const labelWidth = 5
	const { getFolderForId, company } = useLoadContext()
	const [folder, setFolder] = useState(null)
	const [currentSelection, setCurrentSelection] = useState(null)

	useMountEffect(() => {
		getFolderForId({ id: `${company.id}_presentingcv` }).then((folder) => {
			setFolder(folder)
			setCurrentSelection(expirationMapping.forward(folder?.app_expiration_days) ?? "-")
		})
	})

	function makeOptions() {
		const optionSet = expirationMapping.values
		return optionSet
			.filter((item) => item !== currentSelection)
			.map((item) => ({
				text: item,
				onClick: (e) => {
					e.preventDefault()
					setNewExpiration(item)
				},
			}))
	}

	function setNewExpiration(newSelection) {
		const folderId = folder?.folder_id
		const clientId = folderId.slice(0, -13)
		const newExpiration = expirationMapping.backward(newSelection)
		const body = {
			clientId,
			folderId,
			updateType: "expiration",
			newExpiration,
		}
		setCurrentSelection(newSelection)
		updateFolder({ body })
	}

	return (
		<>
			<DropdownFormField
				key={"expSetting"}
				label={"Auto delete data after:"}
				controlId="deleteAfter"
				field={currentSelection}
				header="Delete after:"
				options={makeOptions()}
				colWidths={[labelWidth, dropDownWidth, 12 - dropDownWidth - labelWidth]}></DropdownFormField>
		</>
	)
}
