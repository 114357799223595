import { useState, useCallback, useMemo, ReactNode, useRef } from "react"
import { clientApi, DEFAULT_FONT, getAvailableFonts, useLoadContext, useImage, useFeaturesContext } from "libs"
import { DropdownFormField, BulletFormField, FormField, ImageButton, ExpandCollapse } from "components"
import { FontCaseField } from "components/src/FontCasing/FontCasing"
import { FontName } from "./FontName"
import { BrandColorSelect } from "./BrandColorSelect"
import { BsArrowRepeat } from "react-icons/bs"
import { MdInfoOutline } from "react-icons/md"
import binIcon from "components/images/binIconBlue.png"
import { AraTooltip } from "components/src/AraTooltip/AraTooltip"

export function BrandingTab() {
	const dropDownWidth = 5
	const labelWidth = 5
	const { company, setCompany } = useLoadContext()
	const [currentFontSelection, setCurrentFontSelection] = useState(company.branding?.font ?? DEFAULT_FONT)
	const fontOptions = getAvailableFonts(company)
	const { permissions } = useFeaturesContext()
	const hasDigitalCVs = permissions.uniqueLinks ?? false

	function makeFontOptions() {
		return fontOptions
			.filter((item) => item !== currentFontSelection)
			.map((item) => ({
				text: <FontName font={item} />,
				key: item,
				onClick: (e) => {
					e.preventDefault()
					setNewFont(item)
				},
			}))
	}

	function setNewFont(newSelection) {
		if (company.branding == null) {
			company.branding = {}
		}
		company.branding.font = newSelection
		clientApi.patch({ clientId: company.id, updates: { branding: company.branding } })
		setCompany({ ...company, font: newSelection })
		setCurrentFontSelection(newSelection)
	}

	const colors = useMemo(() => company?.brandColors ?? [], [company?.brandColors])
	const setColors = useCallback(
		(newColors) => {
			company.brandColors = newColors
			clientApi.patch({ clientId: company.id, updates: { brandColors: company.brandColors } })
			setCompany({ ...company })
		},
		[company, setCompany]
	)

	const colWidths = [labelWidth, dropDownWidth, 12 - dropDownWidth - labelWidth]

	return (
		<>
			<DropdownFormField
				key={"fontSetting"}
				label={"Set current font: "}
				controlId="setFont"
				field={currentFontSelection}
				selection={<FontName font={currentFontSelection} />}
				header="Select font:"
				options={makeFontOptions()}
				colWidths={colWidths}></DropdownFormField>
			<BrandColorSelect colors={colors} setColors={setColors} colWidths={colWidths} />
			<ExpandCollapse title="Bullets">
				<BulletFormField key={"bulletSetting"} colWidths={[labelWidth, 2, 6 - labelWidth]}></BulletFormField>
			</ExpandCollapse>
			<ExpandCollapse title="Casing">
				<FontCaseField key={"caseSetting"} colWidths={[labelWidth, 5, 4 - labelWidth]}></FontCaseField>
			</ExpandCollapse>
			{hasDigitalCVs && (
				<ExpandCollapse title="Digital CVs">
					<DigitalCVHeaderUpload hfType="header" colWidths={colWidths}></DigitalCVHeaderUpload>
					<DigitalCVHeaderUpload hfType="footer" colWidths={colWidths}></DigitalCVHeaderUpload>
				</ExpandCollapse>
			)}
		</>
	)
}

function DigitalCVHeaderUpload({
	hfType,
	colWidths,
	children,
}: {
	hfType: "header" | "footer"
	colWidths: Array<number>
	children?: ReactNode
}) {
	const { company, setCompany } = useLoadContext()
	const fileElement = useRef<HTMLInputElement>(null)
	const id = `digital-${hfType}-file-upload`
	const imageName = useMemo(
		() => company?.branding?.digitalCVOptions?.[hfType]?.image ?? null,
		[company?.branding?.digitalCVOptions, hfType]
	)
	const header = useImage()
	const [isUploading, setIsUploading] = useState(false)
	const [isDeleting, setIsDeleting] = useState(false)

	const handleFileChange = useCallback(
		async (e) => {
			setIsUploading(true)
			const file: File = e.target.files[0]
			console.log("file", file)
			const newImageName = await header.setImageAndSendToDb(file, company.id, false)
			const newClientBranding = {
				...company?.branding,
				digitalCVOptions: {
					...company?.branding?.digitalCVOptions,
					[hfType]: {
						...company?.branding?.digitalCVOptions?.[hfType],
						image: newImageName,
					},
				},
			}
			await clientApi.patch({
				clientId: company.id,
				updates: {
					branding: newClientBranding,
				},
			})
			setCompany({ ...company, branding: newClientBranding })
			setIsUploading(false)
		},
		[company, header, hfType, setCompany]
	)

	const deleteFile = useCallback(async () => {
		setIsDeleting(true)
		header.setImage(null)
		const newClientBranding = {
			...company?.branding,
			digitalCVOptions: {
				...company?.branding?.digitalCVOptions,
				[hfType]: {
					...company?.branding?.digitalCVOptions?.[hfType],
					image: null,
				},
			},
		}
		await clientApi.patch({
			clientId: company.id,
			updates: {
				branding: newClientBranding,
			},
		})
		setCompany({ ...company, branding: newClientBranding })
		setIsDeleting(false)
	}, [company, header, hfType, setCompany])

	return (
		<FormField
			controlId="file"
			labelStyle={{ textTransform: "capitalize" }}
			style={{ textAlign: "left" }}
			label={
				<>
					<AraTooltip
						className="prompt-tooltip"
						placement={"left"}
						tooltipLabel={`Upload an image that will be used as the ${hfType} on the Digital CV page.\n Suggested Dimensions: ${SUGGESTED_DIMENSIONS[hfType]}`}>
						<MdInfoOutline size={18} style={{ marginRight: 2, marginBottom: 2 }} />
					</AraTooltip>
					{`Digital ${hfType}:`}
				</>
			}
			colWidths={colWidths}
			rhs={<></>}>
			{imageName == null ? (
				<>
					<label style={{ marginLeft: 0 }} className="LoaderButton SquarerLoaderButton btn btn-light" htmlFor={id}>
						<div className="lbContent">{isUploading && <BsArrowRepeat className="spinning" />}Upload</div>
					</label>
					<input style={{ display: "none" }} id={id} ref={fileElement} onChange={handleFileChange} type="file" />
				</>
			) : (
				<div style={{ display: "flex", alignItems: "center", color: "grey" }}>
					{imageName}
					<ImageButton
						style={{ height: 25, margin: "0 8px" }}
						tooltipProps={{}}
						src={binIcon}
						isLoading={isDeleting}
						alt={`delete-${hfType}`}
						onClick={deleteFile}
					/>
				</div>
			)}
		</FormField>
	)
}

const SUGGESTED_DIMENSIONS = {
	header: "1600x400",
	footer: "1600x200",
}
