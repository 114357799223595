import { Client, DEFAULT_AVAILABLE_CUSTOM_TAGS, useUpdateableHandler, clientApi } from "libs"
import { CardView, LoaderButton } from "components"
import { useState } from "react"
import "./TagsPage.css"

export function TagsPage({
	clientId,
	data,
	setData,
}: {
	clientId: string
	data: Partial<Client>
	setData: (data: Partial<Client>) => void
}): JSX.Element {
	const tagEntries = Object.entries(data?.options?.customTags?.tags ?? {})
	const [localAvailableTags, handleLocalAvailableTags] = useUpdateableHandler(
		data?.options?.customTags?.availableTags ?? DEFAULT_AVAILABLE_CUSTOM_TAGS
	)
	const [isLoading, setIsLoading] = useState(false)

	const setNewTagLimit = () => {
		setIsLoading(true)
		const newOptions = {
			...data.options,
			customTags: {
				...data?.options?.customTags,
				availableTags: parseInt(localAvailableTags),
			},
		}
		const change = {
			options: {
				customTags: {
					availableTags: parseInt(localAvailableTags),
				},
			},
		}
		const newData = { options: newOptions }
		clientApi
			.patch({ clientId, updates: change, changesOnly: true })
			.then(() => {
				setData({ ...data, ...newData })
			})
			.catch(() => {
				alert("Failed to update. Refresh page to check current data")
			})
			.finally(() => {
				setIsLoading(false)
			})
	}
	return (
		<div className="admin-tags-page">
			<div style={{ margin: 20 }}>
				<h3>Available Tags:</h3>
				<div style={{ display: "flex", gap: 5, alignItems: "center", margin: "20px 0" }}>
					Tags Used: {tagEntries.length} /
					<input
						style={{ width: 40 }}
						type="text"
						inputMode="numeric"
						pattern="[0-9]*"
						value={localAvailableTags}
						onChange={handleLocalAvailableTags}></input>
					<LoaderButton
						style={{ marginTop: 0 }}
						className="SquarerLoaderButton"
						isLoading={isLoading}
						onClick={setNewTagLimit}>
						Set New Limit
					</LoaderButton>
				</div>
				<h3>Existing Tags:</h3>
				<div style={{ display: "flex", flexDirection: "column", gap: 20, margin: "20px 0" }}>
					{tagEntries.map(([tagName, tag]) => (
						<CardView key={tagName}>
							<h4>{tag.displayName}</h4>
							<h5>Description:</h5>
							<p>{tag.description ?? ""}</p>
							<h5>Prompt:</h5>
							<p>{tag.prompt}</p>
							{tag.promptOptions != null && (
								<div style={{ display: "flex", gap: 15 }}>
									{Object.entries(tag.promptOptions ?? {}).map(([option, value]) => (
										<div key={option} style={{ display: "flex", gap: 5 }}>
											<div style={{ fontWeight: 500, textTransform: "capitalize" }}>{option}:</div>
											<div>{value?.toString()}</div>
										</div>
									))}
								</div>
							)}
						</CardView>
					))}
				</div>
			</div>
		</div>
	)
}
