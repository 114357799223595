import { useCallback, useEffect, useRef, FocusEventHandler } from "react"
import { mouseWithinWindow } from "../../AppSkeleton/AppSkeleton"

const CLICKOUT_EXCEPTIONS = { relatedTargetTags: ["GRAMMARLY-POPUPS"] }

export function textIsEmptyOrWhitespace(text) {
	return !text || !/\S/gm.test(text)
}

export function useDisablePdfWhileSelecting(pdfId: string, pdfContainerId: string) {
	//a hack to disable the pdf while selecting text. This is because the pdf will steal the mouse events
	// uses a timeout to re-enable the pdf after a max of 800ms
	const selecting = useRef(false)
	const selectionRef = useRef<HTMLElement>()

	const onMouseEnter = useCallback(() => {
		if (selecting.current) {
			const pdfEl = document.getElementById(pdfId)
			if (pdfEl == null) return
			pdfEl.style.pointerEvents = "none"
			setTimeout(() => {
				pdfEl.style.pointerEvents = "auto"
			}, 800)
		}
	}, [pdfId])

	const onMouseLeave = useCallback(() => {
		const pdfEl = document.getElementById(pdfId)
		if (pdfEl != null) {
			pdfEl.style.pointerEvents = "auto"
		}
	}, [pdfId])

	const onMouseDown = () => {
		selecting.current = true
	}

	const onMouseUp = () => {
		selecting.current = false
	}

	useEffect(() => {
		const pdfContainerEl = document.getElementById(pdfContainerId)
		const selectionEl = selectionRef.current
		pdfContainerEl?.addEventListener("mouseleave", onMouseLeave)
		pdfContainerEl?.addEventListener("mouseenter", onMouseEnter)
		selectionEl?.addEventListener("mousedown", onMouseDown)
		document.body.addEventListener("mouseup", onMouseUp)
		return () => {
			pdfContainerEl?.removeEventListener("mouseleave", onMouseLeave)
			pdfContainerEl?.removeEventListener("mouseenter", onMouseEnter)
			selectionEl?.removeEventListener("mousedown", onMouseDown)
			document.body.removeEventListener("mouseup", onMouseUp)
		}
	}, [onMouseEnter, onMouseLeave, pdfContainerId])

	return selectionRef
}

export function isNumber(value) {
	return /^[0-9]*$/.test(value)
}

export const getDMY = (value, hideMonths = false) => {
	const dmy = value?.split("/") ?? [null, "", ""]
	if (hideMonths) {
		dmy[1] = ""
	}
	return dmy
}

export function displayOnlyYears(dateString) {
	return dateString?.split("/")?.[2]
}

export function ifBlurringParent(callback: FocusEventHandler<HTMLDivElement>, includeClickOutOfWindow = true) {
	return (e: React.FocusEvent<HTMLDivElement>) => {
		if (e.relatedTarget != null && CLICKOUT_EXCEPTIONS.relatedTargetTags.includes(e.relatedTarget.tagName)) {
			return
		}
		if (!e.currentTarget.contains(e.relatedTarget) && (includeClickOutOfWindow || mouseWithinWindow.current)) {
			callback?.(e)
		} else {
		}
	}
}
