import { useCallback } from "react"
import { CustomSwatchPicker } from "components"
import { FormField } from "components"

export const BrandColorSelect = ({ colors, setColors, colWidths }) => {
	const addColor = useCallback(
		(color) => {
			const newColors = [...colors, color]
			setColors(newColors)
		},
		[colors, setColors]
	)

	const removeColor = useCallback(
		(index) => () => {
			colors.splice(index, 1)
			setColors([...colors])
		},
		[colors, setColors]
	)

	return (
		<FormField label="Set brand colours:" keyValue="brand-color" controlId={"brand-color"} colWidths={colWidths}>
			<CustomSwatchPicker colors={colors} addColor={addColor} removeColor={removeColor} />
		</FormField>
	)
}
