import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Sidebar } from "../Sidebar/Sidebar"
import { Header } from "../Header/Header"
import "./layout.css"
import { CurrentLink, NavbarLink } from "libs/src/admin/adminLib.types"

export function Layout({
	headerTitle,
	navbarLink,
	clientId,
	children,
	currentLink,
}: {
	headerTitle?: string
	navbarLink: NavbarLink
	clientId?: string
	children?: React.ReactNode
	currentLink: CurrentLink
}) {
	const title = headerTitle?.split("#")?.[1] ?? "-"

	return (
		<>
			<SimpleHeader headerTitle={title} />
			<Container fluid>
				<Row className="main-layout">
					<Col sm={2} className="layout-sidebar">
						{navbarLink ? <Sidebar navbarLink={navbarLink} clientId={clientId} currentLink={currentLink} /> : null}
					</Col>
					<Col sm={10} className="layout-content">
						{children}
					</Col>
				</Row>
			</Container>
		</>
	)
}

function SimpleHeader({ headerTitle }: { headerTitle?: string }) {
	//TODO: Update to css modules
	return (
		<Header>
			<h4 className="layout-header">{headerTitle ? headerTitle : "HireAra"}</h4>
		</Header>
	)
}
