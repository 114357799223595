import { useState, useRef } from "react"
import { useConfigContext, useLoadContext, useLogoContext } from "libs"
import { LoaderButton, CenteredLoaderButton } from "components"
import { onError, bytesToMegabytes } from "libs"
import { useFormFields, useHandler } from "libs"
import { JobFormField, FormField, AutoEntryFormField } from "components"
import Form from "react-bootstrap/Form"
import { validatePassword, validateUserName } from "libs"
import { patchUser } from "libs"

import Auth from "@aws-amplify/auth"

export function AccountTab({ colWidths }) {
	const { user, setUser, company, setCompany } = useLoadContext()
	const [name, handleNameChange] = useHandler(user.name)
	const snapshot = useRef(user.name)
	const { MAX_LOGO_SIZE } = useConfigContext()
	const [email, handleEmailChange] = useState(user.email)
	const [logoIsUploading, setLogoIsUploading] = useState(false)
	const [logButtonDisabled, setLogoButtonDisabled] = useState(true)
	const [isLoading, setIsLoading] = useState(false)
	const [passwordFields, handlePasswordFieldsChange, manualSetPasswordFields] = useFormFields({
		oldPassword: "",
		newPassword: "",
		confirmPassword: "",
	})
	const file = useRef(null)
	const fileElement = useRef(null)
	const { setLogoAndSendToDb } = useLogoContext()

	function handleFileChange(event) {
		file.current = event.target.files[0]
		setLogoButtonDisabled(file.current == null)
	}

	async function submitLogo(event) {
		if (file.current !== null) {
			if (file.current.size > MAX_LOGO_SIZE) {
				// eslint-disable-next-line no-template-curly-in-string
				alert(`Logo should be no bigger than ${bytesToMegabytes(MAX_LOGO_SIZE)}MB`)
				return
			}
			setLogoIsUploading(true)
			try {
				await setLogoAndSendToDb(file.current, company.id)
				console.log("logo uploaded")
				setLogoIsUploading(false)
				file.current = null
				fileElement.current.value = null
				setLogoButtonDisabled(true)
				alert("Logo updated successfully!")
			} catch {}
		}
	}

	async function handleNameSubmit(field) {
		try {
			if (snapshot.current !== field && validateUserName(field)) {
				await patchUser({
					userId: user.id,
					email: user.email,
					name: field,
					clientId: company.id,
					clientName: company.name,
				})
				setUser({ ...user, ...{ name: field } })
				snapshot.current = field
				const newCompany = {
					...company,
					...{
						team: {
							...company.team,
							...{
								[user.id]: {
									user_role: user.role,
									name: field,
									email: user.email,
								},
							},
						},
					},
				}
				setCompany(newCompany)
			}
		} catch (e) {
			onError(e)
		}
	}

	async function handleEmailSubmit(field) {}

	function validatePasswordForm() {
		return (
			passwordFields.oldPassword.length > 0 &&
			validatePassword(passwordFields.newPassword) &&
			passwordFields.newPassword === passwordFields.confirmPassword
		)
	}

	async function onUpdatePassword(event) {
		event.preventDefault()
		setIsLoading(true)
		await updatePassword()
		setIsLoading(false)
	}

	async function updatePassword() {
		try {
			const response = await Auth.currentAuthenticatedUser()
			await Auth.changePassword(response, passwordFields.oldPassword, passwordFields.newPassword)
			//await performLogin(response)
			alert("Password changed")
			manualSetPasswordFields({
				oldPassword: "",
				newPassword: "",
				confirmPassword: "",
			})
		} catch (e) {
			onError(e)
			setIsLoading(false)
		}
	}

	return (
		<>
			<AutoEntryFormField
				autoFocus={true}
				label="Name:"
				field={name}
				fieldHandler={handleNameChange}
				controlId="name"
				type="name"
				submitFunc={handleNameSubmit}
				colWidths={colWidths}></AutoEntryFormField>
			<AutoEntryFormField
				label="Email:"
				disabled={true}
				field={email}
				fieldHandler={handleEmailChange}
				controlId="email"
				type="email"
				submitFunc={handleEmailSubmit}
				colWidths={colWidths}></AutoEntryFormField>
			<FormField controlId="file" label="Logo:" colWidths={[4, 5, 3]} rhs={<></>}>
				<Form.Control ref={fileElement} onChange={handleFileChange} type="file" className="custom-file" />
			</FormField>
			<LoaderButton
				style={{ marginTop: "-10px" }}
				variant="aradark"
				onClick={submitLogo}
				size="large"
				isLoading={logoIsUploading}
				disabled={logButtonDisabled}>
				Update Logo
			</LoaderButton>
			<form id="update-password-form" onSubmit={onUpdatePassword}>
				<JobFormField
					label="Current Password:"
					field={passwordFields.oldPassword}
					fieldHandler={handlePasswordFieldsChange}
					controlId="oldPassword"
					type="password"
					colWidths={colWidths}></JobFormField>
				<JobFormField
					tooltipProps={{
						tooltipLabel:
							"The password must contain a number, a lower case and uppercase, and a special character. Minimum length 8 characters.",
						holdOn: passwordFields.newPassword?.length > 0 && !validatePassword(passwordFields.newPassword),
						highlight: passwordFields.newPassword?.length > 0 && !validatePassword(passwordFields.newPassword),
					}}
					label="New Password:"
					field={passwordFields.newPassword}
					fieldHandler={handlePasswordFieldsChange}
					controlId="newPassword"
					type="password"
					colWidths={colWidths}></JobFormField>
				<JobFormField
					tooltipProps={{
						tooltipLabel: "Confirm password must match new password",
						holdOn:
							passwordFields.confirmPassword?.length > 0 &&
							passwordFields.newPassword !== passwordFields.confirmPassword,
						highlight:
							passwordFields.confirmPassword?.length > 0 &&
							passwordFields.newPassword !== passwordFields.confirmPassword,
					}}
					label="Confirm Password:"
					field={passwordFields.confirmPassword}
					fieldHandler={handlePasswordFieldsChange}
					controlId="confirmPassword"
					type="password"
					colWidths={colWidths}></JobFormField>
				<CenteredLoaderButton
					variant="aradark"
					type="submit"
					size="large"
					isLoading={isLoading}
					disabled={!validatePasswordForm()}>
					Update password
				</CenteredLoaderButton>
			</form>
		</>
	)
}
