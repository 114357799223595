import { memo } from "react"
import "./case-picker.css"

interface CasePickerOption {
	label: string
	value: string
	icon: string
}

export const POSSIBLE_CASE_OPTIONS: CasePickerOption[] = [
	{
		label: "None",
		value: "none",
		icon: "Aa",
	},
	{
		label: "Upper Case",
		value: "uppercase",
		icon: "AA",
	},
	{
		label: "Lower Case",
		value: "lowercase",
		icon: "aa",
	},
	{
		label: "Title Case",
		value: "capitalize",
		icon: "Aa",
	},
]

export const CasePicker = memo(
	({ currentValue, setFormat }: { currentValue: string; setFormat: (arg0: boolean | string) => void }) => {
		return (
			<div className="case-size-options">
				{POSSIBLE_CASE_OPTIONS.map((item) => (
					<CasePickerLabel key={item.label} currentValue={currentValue} item={item} setFormat={setFormat} />
				))}
			</div>
		)
	}
)

export const CasePickerLabel = ({
	currentValue,
	item,
	setFormat,
}: {
	currentValue: string
	item: CasePickerOption
	setFormat: (arg0: boolean | string) => void
}) => {
	return (
		<div
			className="case-size-option-section clickable"
			key={item.label}
			onClick={(e) => {
				setFormat(item.value)
			}}>
			<div className={`case-size-option ${item.value === (currentValue ?? "none") ? "selected-case-option" : ""}`} />
			<div className={`case-size-option`}>{item.label}</div>
			<span className="case-item-icon">{item.value !== "none" && item.icon}</span>
		</div>
	)
}
