import { Route, Switch } from "react-router-dom"
import { MFALogin, NotFound, ForgotPassword, MFAConfirmAccount, Settings } from "containers"
import { AuthenticatedRoute, UnauthenticatedRoute } from "components"
import { AdminClientTable } from "./AdminClientTable/AdminClientTable"
import { ClientDetailPage } from "./ClientDetailPage/ClientDetailPage"
import { AddClient } from "./AddClient/AddClient"
import { DefaultTemplates } from "./DefaultTemplates/DefaultTemplates"

export default function Routes() {
	return (
		<Switch>
			<UnauthenticatedRoute exact path="/">
				<MFALogin />
			</UnauthenticatedRoute>
			<UnauthenticatedRoute path="/login">
				<MFALogin />
			</UnauthenticatedRoute>
			<AuthenticatedRoute path="/accounts">
				<AdminClientTable />
			</AuthenticatedRoute>
			<AuthenticatedRoute path="/client/:clientId/:tab">
				<ClientDetailPage />
			</AuthenticatedRoute>
			<UnauthenticatedRoute path="/forgotpassword">
				<ForgotPassword />
			</UnauthenticatedRoute>
			<UnauthenticatedRoute path="/confirmaccount">
				<MFAConfirmAccount />
			</UnauthenticatedRoute>
			<AuthenticatedRoute path="/changepassword">
				<MFAConfirmAccount />
			</AuthenticatedRoute>
			<AuthenticatedRoute path="/addclient">
				<AddClient />
			</AuthenticatedRoute>
			<AuthenticatedRoute path="/defaulttemplates">
				<DefaultTemplates />
			</AuthenticatedRoute>
			<AuthenticatedRoute path="/settings">
				<Settings />
			</AuthenticatedRoute>
			<Route>
				<NotFound />
			</Route>
		</Switch>
	)
}
