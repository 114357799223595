import { adminAccessApi, useMountEffect } from "libs"
import { useState, useCallback } from "react"
import { LoaderButton } from "components"
import { CustomDropdown } from "components"
import { useQuery } from "libs"
import { useHistory } from "react-router-dom"

export function SuperUserLoginPage({ clientId, data }): JSX.Element {
	const query = useQuery()
	const history = useHistory()
	const [waiting, setWaiting] = useState<boolean>(false)
	const [waitingSingle, setWaitingSingle] = useState<boolean>(false)
	const [waitingSingleTemplate, setWaitingSingleTemplate] = useState<boolean>(false)
	const [signedUrl, setSignedUrl] = useState<string | null>(null)
	const [readOnly, setReadOnly] = useState<string>("read only")
	const [userType, setUserType] = useState<string>("recruiter")
	const [appId, setAppId] = useState<string | undefined>(query.get("appId") || undefined)
	const [editableAppId, setEditableAppId] = useState<string | undefined>(appId)
	const [templateId, setTemplateId] = useState<string | undefined>(query.get("templateId") || undefined)
	const [editableTemplateId, setEditableTemplateId] = useState<string | undefined>(templateId)

	const getSignedUrl = async () => {
		setTemplateId(null)
		setAppId(null)
		setEditableTemplateId(null)
		setEditableAppId(null)
		setWaiting(true)
		const response = (await adminAccessApi.get({ clientId, accessType: readOnly, userType })) as { url: string }
		let signedUrl: string = response?.url
		setWaiting(false)
		if (!signedUrl.startsWith("http")) {
			signedUrl = "https://" + signedUrl
		}
		setSignedUrl(signedUrl)
	}

	const getSingleAppSignedUrl = async () => {
		setTemplateId(null)
		setEditableTemplateId(null)
		setWaitingSingle(true)
		const response = (await adminAccessApi.get({ clientId, singleApplication: appId })) as { url: string }
		let signedUrl: string = response?.url
		setWaitingSingle(false)
		if (!signedUrl.startsWith("http")) {
			signedUrl = "https://" + signedUrl
		}
		setSignedUrl(signedUrl)
	}

	const getSingleTemplateSignedUrl = async () => {
		setAppId(null)
		setEditableAppId(null)
		setWaitingSingleTemplate(true)
		const response = (await adminAccessApi.get({ clientId, singleTemplate: templateId })) as { url: string }
		let signedUrl: string = response?.url
		setWaitingSingleTemplate(false)
		if (!signedUrl.startsWith("http")) {
			signedUrl = "https://" + signedUrl
		}
		setSignedUrl(signedUrl)
	}

	const onChangeReadonly = (arg0: { target: { id: string; value: string } }) => {
		setReadOnly(arg0.target.value)
	}

	const onChangeUserType = (arg0: { target: { id: string; value: string } }) => {
		setUserType(arg0.target.value)
	}

	const appIdRegex = new RegExp(`${clientId}_[0-9a-z-_]{12}[0-9a-f-_]{13}`, "gm")
	const templateIdRegex = new RegExp(`${clientId}_[0-9a-z-_]{12}`, "gm")

	const onChangeUrl = (arg0: { target: { value: string } }) => {
		setEditableAppId(arg0.target.value)
		if (arg0.target.value == null || arg0.target.value === "") {
			setAppId(undefined)
			return
		}
		const result = arg0.target.value.match(appIdRegex)
		if (result == null) {
			setAppId(null)
			return
		}
		try {
			const appId = result[0]
			setAppId(appId)
		} catch {
			setAppId(null)
		}
	}

	const onChangeTemplateUrl = (arg0: { target: { value: string } }) => {
		console.log("changing template id", arg0.target.value)
		setEditableTemplateId(arg0.target.value)
		if (arg0.target.value == null || arg0.target.value === "") {
			setTemplateId(undefined)
			return
		}
		const result = arg0.target.value.match(templateIdRegex)
		if (result == null) {
			setTemplateId(null)
			return
		}
		try {
			const templateId = result[0]
			setTemplateId(templateId)
		} catch {
			setTemplateId(null)
		}
	}

	const onClose = useCallback(() => {
		setSignedUrl(null)
	}, [])

	useMountEffect(() => {
		const getMesssage = (event: MessageEvent) => {
			// if (!['http://localhost:3000', 'https://www.app.hireara.ai'].includes(event.origin)) {return}
			if (typeof event?.data !== "string") {
				if (signedUrl !== null) {
					setTemplateId(null)
				}
				return
			}
			if (event?.data?.startsWith("template")) {
				setTemplateId(event?.data?.split(".")?.[1])
			}
			// if (event?.data?.startsWith("application")) {
			// 	setAppId(event?.data?.split(".")?.[1])
			// }
			if (event?.data === "navigation-disallowed") {
				setTemplateId(null)
				setEditableTemplateId(null)
				setAppId(null)
				setEditableAppId(null)
				onClose()
			}
		}
		window.addEventListener("message", getMesssage)
		return () => {
			window.removeEventListener("message", getMesssage)
		}
	})

	// useEffect(() => {
	// 	// const element = document.getElementById("embedded-login")
	// 	// console.log('checking for element to add listener')
	// 	// if (element != null) {
	// 		const onMessage = (event: MessageEvent) => {
	// 			if (!['http://localhost:3000', 'https://www.app.hireara.ai'].includes(event.origin)) {return}
	// 			if (event.data === "output-finished") {
	// 				onClose()
	// 			}
	// 		}
	// 		window.addEventListener("message", onMessage)
	// 		return () => {
	// 			window.removeEventListener("message", onMessage)
	// 		}
	// 	// }
	// },[onClose, signedUrl])

	const [ref, setRef] = useState(null)

	const bottom = ref?.getBoundingClientRect().bottom ?? 180

	return signedUrl != null ? (
		<div>
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<div>
					{templateId?.length > 0 ? (
						<span
							onClick={() => {
								history.push(`/defaulttemplates?templateId=${templateId}`)
							}}
							style={{ fontSize: 18 }}
							className="clickable blueText">
							{"Create Default Template from this Template -->"}
						</span>
					) : (
						<></>
					)}
				</div>
				<div ref={setRef} className="clickable" onClick={onClose}>
					close [x]
				</div>
			</div>
			<embed
				style={{ width: "100%", height: `calc(100vh - ${bottom}px - 10px)`, border: "solid red 1px inset" }}
				src={signedUrl}></embed>
		</div>
	) : (
		<>
			<h1>Login</h1>
			<h4>Full Access</h4>
			<div style={{ display: "flex", justifyContent: "space-around", margin: "50px 150px" }}>
				<div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
					<div style={{ paddingRight: 15 }}>Access level:</div>
					<CustomDropdown
						value={readOnly}
						actions={READONLY_OPTIONS}
						bestOption={"read only"}
						onChange={onChangeReadonly}></CustomDropdown>
				</div>
				<div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
					<div style={{ paddingRight: 15 }}>User type:</div>
					<CustomDropdown
						value={userType}
						actions={USER_TYPE_OPTIONS}
						bestOption={"recruiter"}
						onChange={onChangeUserType}></CustomDropdown>
				</div>
				<LoaderButton
					style={{ backgroundColor: "var(--ara-gold)", borderColor: "var(--ara-gold)", color: "white" }}
					className="SquarerLoaderButton"
					isLoading={waiting}
					onClick={getSignedUrl}>
					{`Login to ${data.name}`}
				</LoaderButton>
			</div>
			<h4>Edit Single CV</h4>
			<div style={{ display: "flex", justifyContent: "space-around", margin: "50px 50px" }}>
				<div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
					<div style={{ paddingRight: 15 }}>CV URL/ID:</div>
					<input
						style={{ width: 600, borderColor: appId == null ? (appId === null ? "red" : "black") : "green" }}
						type="text"
						value={editableAppId ?? ""}
						onChange={onChangeUrl}></input>
				</div>
				<LoaderButton
					disabled={appId == null}
					style={{ backgroundColor: "var(--ara-gold)", borderColor: "var(--ara-gold)", color: "white" }}
					className="SquarerLoaderButton"
					isLoading={waitingSingle}
					onClick={getSingleAppSignedUrl}>
					{`Login to Edit CV`}
				</LoaderButton>
			</div>
			<h4>Edit Single Template</h4>
			<div style={{ display: "flex", justifyContent: "space-around", margin: "50px 50px" }}>
				<div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
					<div style={{ paddingRight: 15 }}>TEMPLATE URL/ID:</div>
					<input
						style={{ width: 600, borderColor: appId == null ? (appId === null ? "red" : "black") : "green" }}
						type="text"
						value={editableTemplateId ?? ""}
						onChange={onChangeTemplateUrl}></input>
				</div>
				<LoaderButton
					disabled={templateId == null}
					style={{ backgroundColor: "var(--ara-gold)", borderColor: "var(--ara-gold)", color: "white" }}
					className="SquarerLoaderButton"
					isLoading={waitingSingleTemplate}
					onClick={getSingleTemplateSignedUrl}>
					{`Login to Edit Template`}
				</LoaderButton>
			</div>
		</>
	)
}

const USER_TYPE_OPTIONS = [
	{ key: "recruiter", label: "Recruiter" },
	{ key: "admin", label: "Admin" },
	{ key: "brandEditor", label: "Brand Editor" },
]

const READONLY_OPTIONS = [
	{ key: "read only", label: "Read only" },
	{ key: "dataEditable", label: "Data Editable" },
	{ key: "settingsEditable", label: "Settings Editable" },
	{ key: "fullyEditable", label: "Fully Editable" },
]
