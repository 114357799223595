import { useState, useRef } from "react"
import { CaptchaElement, MFASetup, MFAValidate, SignUpCardWithImage, SignUpForm } from "components"
import { useFormFields, validateEmail } from "libs"
import { onError } from "libs"
// import "./../SignUp.css"
import { Auth } from "@aws-amplify/auth"
import { useQuery } from "libs"
import "./ConfirmAccount.css"
import { validatePassword, useLogin } from "libs"
import ReCAPTCHA from "react-google-recaptcha"

export function MFAConfirmAccount() {
	const query = useQuery()
	const email = query.get("email") || ""
	const tempPassword = query.get("tempPassword") || ""
	const captchaRef = useRef<ReCAPTCHA>(null)
	const mostRecentSigninResponse = useRef(null)
	const validationRef = useRef()
	const passwordChangedRef = useRef(false)
	const sessionOverrideRef = useRef()
	const [currentForm, setCurrentForm] = useState("changePassword")
	const mfaConfirmed = useRef(false)
	const performLogin = useLogin({ responseOnly: true, unconfirmed: true })

	const [fields, handleFieldChange] = useFormFields({
		email: email,
		password: tempPassword,
		newPassword: "",
		confirmNewPassword: "",
		code: "",
	})
	const [isLoading, setIsLoading] = useState(false)

	function validateChangePasswordForm() {
		return (
			fields.email.length > 0 &&
			validateEmail(fields.email) &&
			fields.password.length > 0 &&
			validatePassword(fields.newPassword) &&
			fields.newPassword === fields.confirmNewPassword
		)
	}

	function handleChangePasswordSubmit(event) {
		event.preventDefault()

		setIsLoading(true)

		captchaRef.current.execute()
	}

	async function finishLogin() {
		console.log("logging in")
		await signin()
		console.log("signed in")
		await performLogin({ response: mostRecentSigninResponse.current, sessionOverride: sessionOverrideRef.current })
	}

	async function changePassword() {
		// console.log('calling sign in')
		// mostRecentSigninResponse.current = await Auth.signIn(fields.email, fields.password, validationRef.current);
		// console.log('getting session')
		// console.log(await Auth.userSession(mostRecentSigninResponse.current))
		console.log(mostRecentSigninResponse)
		console.log("calling complete new password", mostRecentSigninResponse?.current)
		await Auth.completeNewPassword(mostRecentSigninResponse.current, fields.newPassword)
		passwordChangedRef.current = true
		await attemptSignin()
	}

	async function signin() {
		mostRecentSigninResponse.current = await Auth.signIn(
			fields.email,
			passwordChangedRef.current ? fields.newPassword : fields.password,
			validationRef.current
		)
	}

	async function attemptSignin() {
		console.log("attempting signin")
		await signin()
		console.log(mostRecentSigninResponse.current)
		if (mostRecentSigninResponse.current == null || mfaConfirmed.current) {
			finishLogin()
			return
		}
		if (mostRecentSigninResponse.current.challengeName === "MFA_SETUP") {
			setCurrentForm("setupMFA")
			return
		}
		if (mostRecentSigninResponse.current.challengeName === "SOFTWARE_TOKEN_MFA") {
			setCurrentForm("enterMFA")
			return
		}
		if (
			mostRecentSigninResponse.current.challengeName === "PASSWORD_VERIFIER" ||
			mostRecentSigninResponse.current.challengeName === "NEW_PASSWORD_REQUIRED"
		) {
			await changePassword()
			return
		}
		throw Error(`Unexpected challenge: ${mostRecentSigninResponse.current.challengeName}`)
	}

	async function onCaptchaChange(validationData) {
		validationRef.current = validationData
		try {
			console.log("calling inital signin")
			await attemptSignin()
		} catch (e) {
			onError(e)
			setIsLoading(false)
			captchaRef.current.reset()
		}
	}

	function renderChangePasswordForm() {
		return (
			<SignUpCardWithImage size="large">
				<div className="confirm-account-form-container">
					<SignUpForm
						title={
							<>
								<h2>Welcome to HireAra</h2>
								<h1>Create your account</h1>
							</>
						}
						button={{
							isLoading: isLoading,
							text: "Continue",
							role: "submit",
							onClick: handleChangePasswordSubmit,
							disabled: !validateChangePasswordForm(),
						}}
						fields={[
							{
								label: "Email",
								type: "email",
								placeholder: "Enter your email",
								fieldName: "email",
								initialValue: fields.email,
								autofocus: true,
								autocomplete: "email",
								required: true,
								inputFieldHandler: handleFieldChange,
								description: "Please confirm your email to register new password",
							},
							{
								label: "Temporary Password",
								type: "password",
								placeholder: "Paste temporary password",
								fieldName: "password",
								autocomplete: "current-password",
								required: true,
								initialValue: fields.password,
								inputFieldHandler: handleFieldChange,
								disabled: tempPassword.length !== 0,
							},
							{
								label: "New Password",
								type: "password",
								placeholder: "Create password",
								fieldName: "newPassword",
								autocomplete: "new-password",
								required: true,
								passwordAlert: true,
								initialValue: fields.newPassword,
								inputFieldHandler: handleFieldChange,
							},
							{
								label: "Confirm New password",
								type: "password",
								placeholder: "Confirm password",
								fieldName: "confirmNewPassword",
								autocomplete: "new-password",
								required: true,
								initialValue: fields.confirmNewPassword,
								inputFieldHandler: handleFieldChange,
								description: (
									<span
										style={
											fields.newPassword === fields.confirmNewPassword
												? { display: "none" }
												: { color: "var(--ara-error)" }
										}>
										Password & Confirm Password do not match.
									</span>
								),
							},
						]}
					/>
					<div>
						<CaptchaElement ref={captchaRef} onChange={onCaptchaChange} shouldExecuteAfterErrorReset={isLoading} />
					</div>
				</div>
			</SignUpCardWithImage>
		)
	}

	function renderMFASetup() {
		return (
			<MFASetup
				signinResponse={mostRecentSigninResponse.current}
				handleFieldChange={handleFieldChange}
				fields={fields}
				onSubmit={attemptSignin}
				mfaRef={mfaConfirmed}
				sessionOverrideRef={sessionOverrideRef}
			/>
		)
	}

	function renderMFA() {
		return (
			<MFAValidate
				signinResponse={mostRecentSigninResponse.current}
				handleFieldChange={handleFieldChange}
				fields={fields}
				onSubmit={attemptSignin}
				mfaRef={mfaConfirmed}
			/>
		)
	}

	function renderForm() {
		switch (currentForm) {
			case "changePassword": {
				return renderChangePasswordForm()
			}
			case "setupMFA": {
				return renderMFASetup()
			}
			case "enterMFA": {
				return renderMFA()
			}
			default: {
				return renderChangePasswordForm()
			}
		}
	}

	return <div className="ConfirmAccount">{renderForm()}</div>
}
