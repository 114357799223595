import Form from "react-bootstrap/Form"
import { FormField, FormFieldProps } from "./FormField"
import { AraTooltipProps } from "../AraTooltip/AraTooltip"

interface JobFormFieldProps extends FormFieldProps {
	label: string
	tooltipProps?: AraTooltipProps
	autoFocus?: boolean
	controlId: string
	field: string
	fieldHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
	buttonFlag?: boolean
	buttonHighlighted?: boolean
	key?: string
	buttonPress?: () => void
	type?: string
	colWidths?: number[]
}

export function JobFormField({
	label,
	tooltipProps,
	autoFocus = false,
	controlId,
	field,
	fieldHandler,
	buttonFlag = false,
	key = null,
	buttonPress = null,
	type = "name",
	colWidths = [5, 5, 2],
	...props
}: JobFormFieldProps) {
	const control = (
		<Form.Control
			type={type}
			autoFocus={autoFocus}
			value={key === null ? field : field[key] || ""}
			onChange={fieldHandler}
			{...props}
		/>
	)
	return (
		<FormField
			label={label}
			controlId={controlId}
			key={key}
			keyValue={key || "key"}
			colWidths={colWidths}
			tooltipProps={tooltipProps}
			{...props}>
			{control}
		</FormField>
	)
}
