import { ConfigObject } from "libs"

const config: ConfigObject = {
	site: "admin",
	includeLogoInPdf: true,
	userType: "present",
	baseGetAppsParams: {
		processedOnly: false,
		attrList: [
			"candidateName",
			"receivedTime",
			"identifier",
			"submittedBy",
			"emailAddress",
			"linkedUrl",
			"phoneNumber",
		],
	},
	homepage: "/accounts",
	captchaKey:
		process.env.REACT_APP_IS_TEST === "true" || process.env.REACT_APP_IS_DEV === "true"
			? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
			: process.env.REACT_APP_CAPTCHA_KEY ?? "6Ld5bpYeAAAAALSw49zzZAoaBvQGl9PKL8kzcGN_",
	MAX_LOGO_SIZE: 1500000,
	localDefaultSettings: {
		recruiterPhoto: {
			initState: null,
			storageName: "recruiterPhoto",
			sessionOnly: false,
		},
		recruiterName: {
			initState: null,
			storageName: "recruiterName",
			sessionOnly: false,
		},
		recruiterEmail: { initState: null, storageName: "recruiterEmail", sessionOnly: false },
		companyName: {
			initState: null,
			storageName: "companyName",
			sessionOnly: false,
		},
		jobTitle: { initState: "", storageName: "jobTitle", sessionOnly: false },
	},
	userRoles: ["admin", "support"],
	defaultUserRole: "support",
	accessPermissions: {
		admin: {
			settings: "write",
		},
		support: {
			settings: "none",
		},
	},
	storageKeys: {
		application: [
			//'totalExperience',
			"submittedBy",
			//'averageTenure',
			//'candidateName',
			//'emailAddress',
			"miscData",
			//'score',
			//'phoneNumber',
			"application_id",
			"fileTypes",
			"identifier",
			//'linkedUrl',
			"receivedTime",
		],
	},
	s3: {
		REGION: "eu-west-2",
		BUCKET: "logobucket-46e98a4c-0804-11eb-8223-48d705e1ba8b",
	},
	apiGateway: {
		NAME: "ara_admin_api",
		REGION: "eu-west-2",
		URL:
			(process.env.REACT_APP_ADMIN_API_URL || "https://bm95seyh19.execute-api.eu-west-2.amazonaws.com/Prod") + "/admin",
	},
	cognito: {
		REGION: "eu-west-2",
		USER_POOL_ID: process.env.REACT_APP_ADMIN_USER_POOL_ID || "eu-west-2_5gAlVEl3o",
		APP_CLIENT_ID: process.env.REACT_APP_ADMIN_USER_POOL_CLIENT_ID || "587a4o38fb0s18tfds3u1p03mf", //,
		// IDENTITY_POOL_ID: "eu-west-2:e5b18e71-1983-4cf1-9914-6a63536d67fc"
	},
}

global.config = config

export { config }
