import { useState, useCallback, useMemo } from "react"
import "./CustomBullets.css"
import {
	clientApi,
	DEFAULT_BULLET_COLOR,
	DEFAULT_BULLET_SYMBOL,
	DEFAULT_SUB_BULLET_SYMBOL,
	DEFAULT_BULLET_SPACING_LEFT,
	DEFAULT_BULLET_SPACING_RIGHT,
	useLoadContext,
	DEFAULT_BASE_BULLET_SPACING_LEFT,
	getBrandingStyles,
} from "libs"
import { FormField } from "../JobFormField"
import { ApplyAfterColourDropdown } from "../ColourPicker"
import Dropdown from "react-bootstrap/Dropdown"
import Form from "react-bootstrap/Form"
import "../TextFormatting/TextFormatter.css"

const BULLET_OPTIONS = [
	DEFAULT_BULLET_SYMBOL,
	"-",
	DEFAULT_SUB_BULLET_SYMBOL,
	">",
	"§",
	"■",
	"●",
	"·",
	"◦",
	"♦",
	"○",
	"▪",
]

export function BulletFormField({ autoFocus = false, ...props }): JSX.Element {
	const { company, setCompany } = useLoadContext()
	const [currentBulletSymbol, setCurrentBulletSymbol] = useState<string>(
		company.branding?.bulletSymbol ?? DEFAULT_BULLET_SYMBOL
	)
	const [currentBulletColor, setCurrentBulletColor] = useState<string>(
		company.branding?.bulletColor ?? DEFAULT_BULLET_COLOR
	)
	const [currentSubBulletSymbol, setCurrentSubBulletSymbol] = useState<string>(
		company.branding?.subBulletSymbol ?? DEFAULT_SUB_BULLET_SYMBOL
	)
	const [currentSubBulletColor, setCurrentSubBulletColor] = useState<string>(
		company.branding?.subBulletColor ?? company.branding?.bulletColor ?? DEFAULT_BULLET_COLOR
	)
	const [currentBulletSpacingLeft, setCurrentBulletSpacingLeft] = useState<string>(
		company.branding?.bulletSpacingLeft ?? DEFAULT_BULLET_SPACING_LEFT
	)
	const [currentBaseBulletSpacingLeft, setCurrentBaseBulletSpacingLeft] = useState<string>(
		company.branding?.baseBulletSpacingLeft ?? DEFAULT_BASE_BULLET_SPACING_LEFT
	)
	const [currentBulletSpacingRight, setCurrentBulletSpacingRight] = useState<string>(
		company.branding?.bulletSpacingRight ?? DEFAULT_BULLET_SPACING_RIGHT
	)

	const setBranding = useCallback(
		(key: string, localSet: React.Dispatch<string>) => (newValue) => {
			if (company.branding == null) {
				company.branding = {}
			}
			company.branding[key] = newValue
			clientApi.patch({ clientId: company.id, updates: { branding: company.branding } })
			setCompany({ ...company, branding: { ...company.branding } })
			localSet(newValue)
		},
		[company, setCompany]
	)

	const setBulletColor = useMemo(() => setBranding("bulletColor", setCurrentBulletColor), [setBranding])
	const setNewBullet = useMemo(() => setBranding("bulletSymbol", setCurrentBulletSymbol), [setBranding])
	const setSubBulletColor = useMemo(() => setBranding("subBulletColor", setCurrentSubBulletColor), [setBranding])
	const setNewSubBullet = useMemo(() => setBranding("subBulletSymbol", setCurrentSubBulletSymbol), [setBranding])
	const setBulletSpacingLeft = useMemo(
		() => setBranding("bulletSpacingLeft", setCurrentBulletSpacingLeft),
		[setBranding]
	)
	const setBaseBulletSpacingLeft = useMemo(
		() => setBranding("baseBulletSpacingLeft", setCurrentBaseBulletSpacingLeft),
		[setBranding]
	)
	const setBulletSpacingRight = useMemo(
		() => setBranding("bulletSpacingRight", setCurrentBulletSpacingRight),
		[setBranding]
	)

	const options = useMemo(() => {
		const optionSet = BULLET_OPTIONS
		return optionSet
			.filter((item) => item !== currentBulletSymbol)
			.map((item) => ({
				text: <div style={{ color: currentBulletColor }}>{item}</div>,
				key: item,
				onClick: (e) => {
					e.preventDefault()
					setNewBullet(item)
				},
			}))
	}, [currentBulletColor, currentBulletSymbol, setNewBullet])

	const rhs = useMemo(
		() => (
			<div style={{ marginTop: -3, marginLeft: 5 }}>
				<ApplyAfterColourDropdown color={currentBulletColor} onChange={setBulletColor} />
			</div>
		),
		[currentBulletColor, setBulletColor]
	)

	const subOptions = useMemo(() => {
		const optionSet = BULLET_OPTIONS
		return optionSet
			.filter((item) => item !== currentSubBulletSymbol)
			.map((item) => ({
				text: <div style={{ color: currentSubBulletColor }}>{item}</div>,
				key: item,
				onClick: (e) => {
					e.preventDefault()
					setNewSubBullet(item)
				},
			}))
	}, [currentSubBulletColor, currentSubBulletSymbol, setNewSubBullet])

	const subRhs = useMemo(
		() => (
			<div style={{ marginTop: -3, marginLeft: 5 }}>
				<ApplyAfterColourDropdown color={currentSubBulletColor} onChange={setSubBulletColor} />
			</div>
		),
		[currentSubBulletColor, setSubBulletColor]
	)

	return (
		<>
			<FormField label={"Set bullet point style: "} keyValue={0} rhs={rhs} controlId={"setBullet"} {...props}>
				<Dropdown className="bullet-form-field">
					<Dropdown.Toggle split id="dropdown-item-button" variant="aralight" style={{ width: 80 }}>
						<span
							style={{
								color: currentBulletColor,
							}}>
							{currentBulletSymbol}
						</span>
					</Dropdown.Toggle>
					<Dropdown.Menu align="start">
						{<Dropdown.Header>Select bullet symbol:</Dropdown.Header>}
						{options.map((option) => (
							<Dropdown.Item key={option.key ?? option.text.toString()} onClick={option.onClick}>
								{option.text}
							</Dropdown.Item>
						))}
					</Dropdown.Menu>
				</Dropdown>
			</FormField>
			<FormField label={"Set sub-bullet point style: "} keyValue={1} rhs={subRhs} controlId={"setSubBullet"} {...props}>
				<Dropdown className="bullet-form-field">
					<Dropdown.Toggle split id="dropdown-item-button" variant="aralight" style={{ width: 80 }}>
						<span
							style={{
								color: currentSubBulletColor,
							}}>
							{currentSubBulletSymbol}
						</span>
					</Dropdown.Toggle>
					<Dropdown.Menu align="start">
						{<Dropdown.Header>Select sub-bullet symbol:</Dropdown.Header>}
						{subOptions.map((option) => (
							<Dropdown.Item key={option.key ?? option.text.toString()} onClick={option.onClick}>
								{option.text}
							</Dropdown.Item>
						))}
					</Dropdown.Menu>
				</Dropdown>
			</FormField>
			<FormField
				label="Starting Indent:"
				// tooltipProps={{ tooltipLabel: "Spacing between bullet and text. Scales with the font size." }}
				keyValue={2}
				rhs={
					<div style={{ position: "relative" }}>
						<div
							style={{
								position: "absolute",
								top: -18.5,
								left: -6,
								padding: "18px 20px",
								overflow: "hidden",
								width: "max-content",
								maxWidth: 240,
								height: 158,
								border: "1px solid #bbbbbb",
								borderRadius: "0.25rem",
							}}>
							<b>Example:</b>
							<div
								className="text-format"
								style={{
									fontSize: 13,
									minWidth: 150,
									borderLeft: "1px dashed #eeeeee",
									marginTop: 5,
									...getBrandingStyles({
										company: {
											branding: {
												font: company.branding.font,
												bulletSymbol: currentBulletSymbol,
												bulletColor: currentBulletColor,
												subBulletSymbol: currentSubBulletSymbol,
												subBulletColor: currentSubBulletColor,
												bulletSpacingLeft: currentBulletSpacingLeft,
												baseBulletSpacingLeft: currentBaseBulletSpacingLeft,
												bulletSpacingRight: currentBulletSpacingRight,
											},
										},
									}),
								}}>
								<p>Paragraph Text</p>
								<ul>
									<li style={{ whiteSpace: "nowrap" }}>Bullet Text</li>
									<li className="hide-bullet">
										<ul>
											<li style={{ whiteSpace: "nowrap" }}>More Text</li>
											<li className="hide-bullet">
												<ul>
													<li style={{ whiteSpace: "nowrap" }}>More Text</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</div>
						</div>
					</div>
				}
				controlId={"setBaseBulletSpacing"}
				{...props}
				colWidths={[props.colWidths[0], "auto", "auto"]}>
				<SpacingControl
					currentBulletSpacing={currentBaseBulletSpacingLeft}
					setCurrentBulletSpacing={setCurrentBaseBulletSpacingLeft}
					setBulletSpacing={setBaseBulletSpacingLeft}
					defaultSpacing={DEFAULT_BASE_BULLET_SPACING_LEFT}
					min={-1}
				/>
			</FormField>
			<FormField
				label={"Indent: "}
				// tooltipProps={{ tooltipLabel: "Indent to left of bullet. Scales with the font size." }}
				keyValue={2}
				rhs={<></>}
				controlId={"setBulletSpacing"}
				{...props}
				colWidths={[props.colWidths[0], "auto", "auto"]}>
				<SpacingControl
					currentBulletSpacing={currentBulletSpacingLeft}
					setCurrentBulletSpacing={setCurrentBulletSpacingLeft}
					setBulletSpacing={setBulletSpacingLeft}
					defaultSpacing={DEFAULT_BULLET_SPACING_LEFT}
					max={3}
				/>
			</FormField>
			<FormField
				label="Bullet to Text Space:"
				// tooltipProps={{ tooltipLabel: "Spacing between bullet and text. Scales with the font size." }}
				keyValue={2}
				rhs={<></>}
				controlId={"setBulletSpacing"}
				{...props}
				colWidths={[props.colWidths[0], "auto", "auto"]}>
				<SpacingControl
					currentBulletSpacing={currentBulletSpacingRight}
					setCurrentBulletSpacing={setCurrentBulletSpacingRight}
					setBulletSpacing={setBulletSpacingRight}
					defaultSpacing={DEFAULT_BULLET_SPACING_RIGHT}
				/>
			</FormField>
		</>
	)
}

const SpacingControl = ({
	currentBulletSpacing,
	setCurrentBulletSpacing,
	setBulletSpacing,
	defaultSpacing,
	min = 0,
	max = 2,
}) => (
	<Form.Control
		style={{ width: 80 }}
		type="number"
		min={min}
		max={max}
		step={0.1}
		value={currentBulletSpacing}
		onChange={(e) => {
			setCurrentBulletSpacing(e.target.value)
		}}
		onBlur={async () => {
			const boundedValue = Math.min(
				max,
				Math.max(min, parseFloat(currentBulletSpacing?.length > 0 ? currentBulletSpacing : defaultSpacing))
			)
			await setBulletSpacing(boundedValue.toString())
		}}
	/>
)
