import { download } from "./fileUtils"

export function downloadCSV(data: object[], fields: string[], filename: string) {
	const csv = convertToCSV(data, fields)
	const blob = new Blob([csv], { type: "text/csv" })
	download(blob, filename)
}

export function convertToCSV(objArray: object[], fields: string[]) {
	let csvContent = fields.join(",") + "\n"
	objArray.forEach((obj) => {
		const row = fields.map((fieldName) => {
			const value = obj[fieldName]
			if (value == null) {
				return ""
			}
			if (typeof value === "string") {
				return `"${value.replace(/"/g, '""')}"`
			}
			return value
		})
		csvContent += row.join(",") + "\n"
	})
	return csvContent
}
