import { forwardRef } from "react"
import "./header.css"

export const Header = forwardRef(
	(
		{
			className,
			children,
			...props
		}: {
			className?: string
			children?: React.ReactNode
		},
		ref: React.Ref<HTMLDivElement>
	) => {
		return (
			<div ref={ref} className="header" {...props}>
				<div className="header-content"> {children}</div>
			</div>
		)
	}
)
