import { recruiterTagNames, useAppContext, useConfigContext } from "libs"
import { useStickyState } from "libs"
import { AutoEntryFormField, RecruiterPhoto } from "components"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import defaultSilhouette from "components/images/defaultSilhouette.png"
import { AdminText } from "./AdminText"

export function DefaultsTab({ colWidths }) {
	const { localDefaultSettings } = useConfigContext()
	const { user, company } = useAppContext()
	var [recruiterName, setRecruiterName] = useStickyState({
		...localDefaultSettings.recruiterName,
		initState: user.name,
	})
	const handleRecruiterNameChange = (e) => {
		recruiterName = e.target.value
		setRecruiterName(recruiterName)
	}
	const handleRecruiterNameSubmit = console.log
	var [companyName, setCompanyName] = useStickyState({ ...localDefaultSettings.companyName, initState: company.name })
	const handleCompanyNameChange = (e) => {
		companyName = e.target.value
		setCompanyName(companyName)
	}
	const handleCompanyNameSubmit = console.log
	var [jobTitle, setJobTitle] = useStickyState({ ...localDefaultSettings.jobTitle, initState: user.jobTitle })
	const handleJobTitleChange = (e) => {
		jobTitle = e.target.value
		setJobTitle(jobTitle)
	}
	const handleJobTitleSubmit = console.log
	const [recruiterPhoto, setRecruiterPhoto] = useStickyState({
		...localDefaultSettings.recruiterPhoto,
		initState: defaultSilhouette,
	})
	var [recruiterEmail, setRecruiterEmail] = useStickyState({
		...localDefaultSettings.recruiterEmail,
		initState: user.email,
	})
	var [recruiterPhone, setRecruiterPhone] = useStickyState({
		...localDefaultSettings.recruiterPhone,
		initState: user.phone,
	})
	const handleRecruiterEmailChange = (e) => {
		recruiterEmail = e.target.value
		setRecruiterEmail(recruiterEmail)
	}
	const handleRecruiterPhoneChange = (e) => {
		recruiterPhone = e.target.value
		setRecruiterPhone(recruiterPhone)
	}
	const handleRecruiterEmailSubmit = console.log
	const handleRecruiterPhoneSubmit = console.log

	return (
		<>
			<AdminText>Defaults values for CV Display: </AdminText>
			<AutoEntryFormField
				autoFocus={true}
				label={`${recruiterTagNames.recruiterName}:`}
				field={recruiterName}
				fieldHandler={handleRecruiterNameChange}
				controlId="name"
				type="name"
				submitFunc={handleRecruiterNameSubmit}
				colWidths={colWidths}></AutoEntryFormField>
			<AutoEntryFormField
				label={`${recruiterTagNames.companyName}:`}
				field={companyName}
				fieldHandler={handleCompanyNameChange}
				controlId="name"
				type="name"
				submitFunc={handleCompanyNameSubmit}
				colWidths={colWidths}></AutoEntryFormField>
			<AutoEntryFormField
				label={`${recruiterTagNames.recruiterEmail}:`}
				field={recruiterEmail}
				fieldHandler={handleRecruiterEmailChange}
				controlId="name"
				type="name"
				submitFunc={handleRecruiterEmailSubmit}
				colWidths={colWidths}></AutoEntryFormField>
			<AutoEntryFormField
				label={`${recruiterTagNames.recruiterPhone}:`}
				field={recruiterPhone ?? ""}
				fieldHandler={handleRecruiterPhoneChange}
				controlId="phone"
				type="tel"
				submitFunc={handleRecruiterPhoneSubmit}
				colWidths={colWidths}></AutoEntryFormField>
			<AutoEntryFormField
				label={`${recruiterTagNames.jobTitle}:`}
				field={jobTitle}
				fieldHandler={handleJobTitleChange}
				controlId="name"
				type="name"
				submitFunc={handleJobTitleSubmit}
				colWidths={colWidths}></AutoEntryFormField>
			<Row className="jobFormField">
				<Col style={{ textAlign: "right" }} xs={4}>
					Display photo:
				</Col>
				<Col xs={6}>
					<RecruiterPhoto recruiterPhoto={recruiterPhoto} setRecruiterPhoto={setRecruiterPhoto} />
				</Col>
			</Row>
		</>
	)
}
