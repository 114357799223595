import { useCallback, useEffect, useMemo, useState } from "react"
import {
	adminGetTemplates,
	adminAddTemplate,
	useMountEffect,
	Template,
	getDateFromUnix,
	adminGetDefaultTemplates,
	DEFAULT_AVAILABLE_CUSTOM_TAGS,
	clientApi,
	alphabeticalComparer,
} from "libs"
import { CardView, CustomDropdown, LoaderButton, SortableTableCard } from "components"
import { useHistory } from "react-router-dom"

export function TemplatesPage({ clientId, data, setData }) {
	const [waiting, setWaiting] = useState<boolean>(false)
	const [addingTemplate, setAddingTemplate] = useState<boolean>(false)
	const [templates, setTemplates] = useState<Template[]>([])
	const [selectedDefaultTemplate, setSelectedDefaultTemplate] = useState<string>(null)
	const [defaultTemplates, setDefaultTemplates] = useState<any[]>([])

	const tagsToAdd = useMemo(
		() =>
			Object.keys(
				defaultTemplates.find((template) => template.default_template_id === selectedDefaultTemplate)?.customTags ?? {}
			),
		[defaultTemplates, selectedDefaultTemplate]
	)
	const numTags = tagsToAdd.length ?? 0
	const currentTags = useMemo(() => Object.keys(data.options?.customTags?.tags ?? {}), [data.options?.customTags?.tags])
	const currentNumTags = currentTags.length ?? 0
	const availableTags = data.options?.customTags?.availableTags ?? DEFAULT_AVAILABLE_CUSTOM_TAGS
	const tooManyTagsInTemplate = useMemo(
		() => new Set([...tagsToAdd, ...currentTags]).size > availableTags,
		[availableTags, currentTags, tagsToAdd]
	)

	const defaultTemplateWarning = useMemo(() => {
		if (numTags > 0 && tooManyTagsInTemplate) {
			return `Client has ${currentNumTags}/${availableTags} available custom tags and this template has ${numTags} custom tag${
				numTags === 1 ? "" : "s"
			}. To apply this template you need to increase the available tags.`
		}
		return null
	}, [availableTags, currentNumTags, numTags, tooManyTagsInTemplate])

	const fetchTemplates = useCallback(() => {
		setWaiting(true)
		adminGetTemplates({ clientId, attributes: ["templateName", "createdTime", "fromTemplate"] })
			.then((templates) => {
				setTemplates(templates)
			})
			.finally(() => {
				setWaiting(false)
			})

		adminGetDefaultTemplates({ attributes: ["templateName", "customTags"] }).then((res) => {
			setDefaultTemplates(res)
		})
	}, [clientId])

	const createTemplate = useCallback(() => {
		setAddingTemplate(true)
		adminAddTemplate({ clientId, defaultTemplateId: selectedDefaultTemplate })
			.then((res) => {
				setTemplates((prev) => prev.concat(res))
				fetchTemplates()
			})
			.finally(() => {
				clientApi.get({ clientId, query: ["miscData"] }).then((res) => {
					setData(res)
				})
			})
			.finally(() => {
				setAddingTemplate(false)
			})
	}, [clientId, fetchTemplates, selectedDefaultTemplate, setData])

	useMountEffect(() => {
		// fetch users for client
		fetchTemplates()
	})

	return (
		<div className="account-container">
			<h1>Templates</h1>
			<CardView style={{ margin: 16 }}>
				<h4>Add new template from default</h4>
				<div style={{ display: "flex", alignItems: "center", gap: 20 }}>
					<div style={{ minWidth: 250 }}>
						<CustomDropdown
							actions={defaultTemplates
								.sort((a, b) => alphabeticalComparer(a.templateName, b.templateName))
								.map((template) => {
									const numTags = Object.keys(template.customTags ?? {})?.length ?? 0
									return {
										label: `${template.templateName} (${numTags} custom tag${numTags === 1 ? "" : "s"})`,
										key: template.default_template_id,
									}
								})}
							onChange={(e) => {
								setSelectedDefaultTemplate(e.target.value)
							}}
							value={selectedDefaultTemplate}
							bestOption={"Select a default template"}
						/>
					</div>
					<LoaderButton
						className="btn btn-primary SquarerLoaderButton"
						style={{
							backgroundColor: "var(--ara-gold)",
							borderColor: "var(--ara-gold)",
							color: "white",
						}}
						onClick={createTemplate}
						isLoading={addingTemplate}
						disabled={addingTemplate || selectedDefaultTemplate == null || tooManyTagsInTemplate}>
						Add
					</LoaderButton>
					<div>{defaultTemplateWarning != null && <div className="redText">{defaultTemplateWarning}</div>}</div>
				</div>
			</CardView>
			<TemplateTable
				filteredTemplates={templates}
				isRefreshing={waiting}
				pageNumber={0}
				fetchTemplates={fetchTemplates}
				setTemplates={setTemplates}></TemplateTable>
		</div>
	)
}

function sortByCreationTime(a, b) {
	return b?.createdTime - a?.createdTime
}

function TemplateTable({ filteredTemplates, isRefreshing, pageNumber, fetchTemplates, setTemplates }) {
	const [tableRows, setTableRows] = useState([])
	const history = useHistory()

	useEffect(() => {
		createRows(Object.values(filteredTemplates))
	}, [filteredTemplates])

	function createRows(data) {
		const rows = data.map(createRow)
		setTableRows(rows)
	}

	function createRow(templateData) {
		const templateName =
			templateData.templateName != null && templateData.templateName?.length > 0 ? templateData.templateName : "-"
		const clientId = templateData.client_id ?? templateData.clientId
		return {
			...templateData,
			templateName,
			date: getDateFromUnix(templateData.createdTime).toLocaleString(),
			goToTemplate: (
				<div
					className="blueText clickable"
					onClick={() => {
						history.push(`/client/${clientId}/login?templateId=${templateData.template_id}`)
					}}>
					{"Go to Template -->"}
				</div>
			),
		}
	}

	return (
		<SortableTableCard
			pageNumber={pageNumber}
			headings={["Template Name", "Created Date", ""]}
			info={tableRows}
			rowIdKey="template_id"
			sortFunc={sortByCreationTime}
			infoKeys={["templateName", "date", "goToTemplate"]}
			rowFunc={() => {}}
			refreshProps={{ onClick: fetchTemplates, isLoading: isRefreshing }}></SortableTableCard>
	)
}
