import { AraTooltip } from "../AraTooltip/AraTooltip"
import { useClickOutside } from "libs"
import cross from "../../images/white-cross.png"
import { HexColorPicker, HexColorInput } from "react-colorful"
import { useCallback, useState } from "react"

export function CustomSwatchPicker({ colors, addColor, removeColor }) {
	return (
		<div className="custom-swatch-row">
			{colors.map((color, index) => (
				<CustomSwatch key={color} color={color} removeColor={removeColor(index)} />
			))}
			<AddCustomSwatchButton addColor={addColor} />
		</div>
	)
}

function CustomSwatch({ color, removeColor }) {
	return (
		<AraTooltip tooltipLabel={color} placement="bottom">
			<div className="custom-swatch" style={{ backgroundColor: color }}>
				<img
					alt="cross"
					src={cross}
					className="remove-cross clickable"
					onClick={() => {
						console.log(color, "clicked")
						removeColor()
					}}></img>
			</div>
		</AraTooltip>
	)
}

function AddCustomSwatchButton({ addColor }) {
	const [isOpen, toggle] = useState(false)
	const [localColor, setLocalColor] = useState(null)

	const close = useCallback(() => {
		if (localColor != null) {
			addColor(localColor)
		}
		toggle(false)
	}, [addColor, localColor])
	const pickerRef = useClickOutside(close)

	return (
		<div className="picker">
			<AraTooltip tooltipLabel={"add new colour"} placement="bottom" timeToShow={600} timeToHide={100}>
				<div className="custom-swatch custom-swatch-button clickable" onClick={() => toggle(true)}>
					+
				</div>
			</AraTooltip>

			{isOpen && (
				<div className={`popover align-left`} ref={pickerRef}>
					<div style={{ display: "flex", justifyContent: "space-around" }}>
						<div style={{ margin: "auto 0", height: "fit-content" }}>Current selection: </div>
						<div className="custom-swatch" style={{ backgroundColor: localColor }}></div>
					</div>
					<HexColorPicker color={localColor ?? "#3c767b"} onChange={setLocalColor} />
					<HexColorInput color={localColor ?? "#3c767b"} onChange={setLocalColor} />
				</div>
			)}
		</div>
	)
}
