import Button from "react-bootstrap/Button"
import { BsArrowRepeat } from "react-icons/bs"
import "./RefreshButton.css"

export function RefreshButton({ isLoading, className = "", disabled = false, ...props }) {
	return (
		<Button
			aria-label="refresh-button"
			className={`RefreshButton ${className}`}
			disabled={disabled || isLoading}
			variant="light"
			{...props}>
			{isLoading ? <BsArrowRepeat className="spinner spinning" /> : <BsArrowRepeat className="spinner" />}
			<div>{props.children}</div>
		</Button>
	)
}
