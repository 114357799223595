import { LoaderButton } from "components"
import { Client, downloadCSV, adminGetStats } from "libs"
import { useState } from "react"

export function AnalyticsPage({ clientId, data }: { clientId: string; data: Partial<Client> }): JSX.Element {
	const [isLoading, setIsLoading] = useState(false)
	const [since, setSince] = useState(dateToYYYYMMDD(new Date(1686700800000)))

	const downloadStats = async () => {
		console.log(since)
		const ymd = since.split("-")
		const sincetoSend = new Date(parseInt(ymd[0]), parseInt(ymd[1]) - 1, parseInt(ymd[2])).getTime() / 1000
		console.log(sincetoSend)
		setIsLoading(true)
		const response = await adminGetStats({ clientId, stats: "cvsUploaded", since: sincetoSend })
		downloadCSV(
			response,
			["externalId", "submittedBy", "location", "editingTimeMin", "readTime", "outputTime", "pk", "template"],
			`${data.name}_stats.csv`
		)
		setIsLoading(false)
	}

	return (
		<div>
			<h1>Analytics</h1>
			<div style={{ display: "flex", gap: 10 }}>
				<LoaderButton onClick={downloadStats} isLoading={isLoading} className="SquarerLoaderButton">
					Download Stats CSV
				</LoaderButton>
				<input
					type="date"
					value={since}
					onChange={(e) => {
						setSince(e.target.value)
					}}
				/>
			</div>
		</div>
	)
}

function dateToYYYYMMDD(date: Date): string {
	return `${date.getFullYear()}-${(date.getMonth() + 1).toFixed(0).padStart(2, "0")}-${date
		.getDate()
		.toFixed(0)
		.padStart(2, "0")}`
}
