import "./ToggleSwitch.css"

export function ControlPanelBox({ label, showLabel = true, children }) {
	return (
		<>
			{showLabel !== false && (
				<div style={{ textAlign: "center", width: "fit-content", margin: "0 auto", fontSize: 11 }}>{label}</div>
			)}
			{children}
		</>
	)
}

export function ToggleSwitch({ label, value, onChange, disabled = false, showLabel = true }) {
	return (
		<ControlPanelBox showLabel={showLabel} label={label}>
			<PlainToggleSwitch label={label} value={value} onChange={onChange} disabled={disabled} />
		</ControlPanelBox>
	)
}

export function PlainToggleSwitch({ label, value, onChange, disabled = false }) {
	const name = `toggleswitch-${label}`
	return (
		<div className="toggle-switch">
			<input
				type="checkbox"
				className="toggle-switch-checkbox"
				name={name}
				id={name}
				checked={value}
				onChange={(e) => {
					if (!disabled) {
						onChange(e.target.checked)
					}
				}}
			/>
			<label className="toggle-switch-label" htmlFor={name}>
				<span className={`toggle-switch-inner ${disabled && "toggle-inner-disabled"}`} />
				<span data-testid={name} className={`toggle-switch-switch ${disabled && "toggle-switch-disabled"}`} />
			</label>
		</div>
	)
}
