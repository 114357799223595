import { Link } from "react-router-dom"
import "./link-button.css"
import { addStickyValue } from "libs"
import { CurrentLink } from "libs/src/admin/adminLib.types"

export const LinkButton = ({
	className,
	clientId,
	path,
	current,
	children,
}: {
	className?: string
	clientId?: string
	path: string
	current: CurrentLink
	children?: React.ReactNode
}) => {
	const link = clientId ? `/client/${clientId}/${path}` : `${path}`
	const currentLink = current.includes(path)

	return (
		<Link
			onClick={() => {
				addStickyValue({ storageName: "clientDetailsTab", value: path, sessionOnly: false, userId: "admin" })
			}}
			to={link}
			className={`${currentLink ? "link-button-active" : "link-button"} ${className == null ? "" : className}`}>
			{children}
		</Link>
	)
}
