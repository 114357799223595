import { CustomDropdown } from "components"
import { fieldSet, getCurrentStatus, clientApi } from "libs"
import { useEffect, useState } from "react"
import FormLabel from "react-bootstrap/FormLabel"
import "./AccountForm.css"
import { ClientProps } from "Utils/client.types"

interface PrevStateProps {
	clientId: string
	credits?: {
		monthly: string
		annually: number
	}
	billingDate?: string
	paymentType?: string
	miscData?: string
	freeTrialEnd?: string
}

export function AccountForm({
	data,
	clientId,
	setData,
}: {
	data: any
	clientId: string
	setData: (data: ClientProps) => void
}) {
	const [freeTrial, setFreeTrial] = useState(false)
	const [formField, setFormField] = useState<PrevStateProps>({
		clientId: clientId,
	})
	const [changeTrigger, setChangeTrigger] = useState(false)

	const onSubmit = (body) => {
		clientApi.patch({ clientId, updates: body })
		setChangeTrigger(false)
		console.log(body, data)
		setData({ ...data, ...body })
	}

	useEffect(() => {
		if (changeTrigger) {
			const body = {
				...formField,
			}
			onSubmit(body)
		}
	}, [formField])

	const onChange = (event) => {
		setFormField((prevState: PrevStateProps) => {
			if (event.target.id === "credits") {
				return {
					...prevState,
					[event.target.id]: {
						monthly: parseInt(event.target.value),
						annually: parseInt(event.target.value) * 12,
					},
				}
			}
			if (event.target.id === "miscData") {
				return {
					...prevState,
					[event.target.id]: "client_status#" + event.target.value,
				}
			}
			return {
				...prevState,
				[event.target.id]: event.target.value,
			}
		})
	}

	useEffect(() => {
		const statusCheck = "client_status#free_trial"
		if (data?.miscData === statusCheck || formField?.miscData === statusCheck) {
			setFreeTrial(true)
		} else {
			setFreeTrial(false)
		}
	}, [data?.miscData, formField?.miscData])

	return (
		<form onSubmit={onSubmit}>
			<div className="account-form-container">
				<div className="row-one">
					<FormLabel>Monthly Credits</FormLabel>
				</div>
				<div className="row-two">
					<input
						id="credits"
						type="text"
						inputMode="numeric"
						onChange={onChange}
						value={formField?.credits?.monthly ?? data?.credits?.monthly}
						onBlur={() => onSubmit(formField)}
					/>
				</div>
			</div>
			<div className="account-form-container">
				<div className="row-one">
					<label>Billing Start Date</label>
				</div>
				<div className="row-two">
					<input
						className="account-form-date"
						type="date"
						onBlur={(e) => {
							//  Note: This is a known issue with the date picker component.
							// TODO: Create a custom date picker component.

							onChange({
								target: {
									id: "billingDate",
									value: e.target.value,
								},
							})
							setChangeTrigger(true)
						}}
						onChange={(e) => {
							onChange({
								target: {
									id: "billingDate",
									value: e.target.value,
								},
							})
						}}
						value={formField?.billingDate || data?.billingDate || ""}
					/>
				</div>
			</div>
			<div className="account-form-container">
				<div className="row-one">
					<FormLabel className="label">Payment Type:</FormLabel>
				</div>
				<div className="row-two">
					<CustomDropdown
						actions={fieldSet.paymentType}
						bestOption="-"
						onChange={onChange}
						formId="paymentType"
						value={data?.paymentType || formField?.paymentType}
						setChangeTrigger={setChangeTrigger}
					/>
				</div>
			</div>
			<div className="account-form-container">
				<div className="row-one">
					<FormLabel className="label">Status:</FormLabel>
				</div>
				<div className="row-two">
					<CustomDropdown
						actions={fieldSet?.miscData}
						bestOption="Free Trial"
						onChange={onChange}
						formId="miscData"
						value={getCurrentStatus(data?.miscData) || formField?.miscData}
						setChangeTrigger={setChangeTrigger}
					/>
				</div>
				<div>
					{freeTrial ? (
						<div className="row-three">
							<label
								style={{
									paddingRight: "10px",
								}}>
								Free Trial End
							</label>
							<input
								type="date"
								className="account-form-date"
								onBlur={(e) => {
									//  Note: This is a known issue with the date picker component.
									onChange({
										target: {
											id: "freeTrialEnd",
											value: e.target.value,
										},
									})
									setChangeTrigger(true)
								}}
								onChange={(e) =>
									onChange({
										target: { id: "freeTrialEnd", value: e.target.value },
									})
								}
								value={formField?.freeTrialEnd || data?.freeTrialEnd || ""}
							/>
						</div>
					) : null}
				</div>
			</div>

			{/* <div className="account-form-container">
        <label>Account Access</label>
        <button className="account-access-button" type="submit">
          <span className="account-access-button-span">Login</span>
        </button>
      </div> */}
		</form>
	)
}
