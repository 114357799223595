import { UserFromDb, useUpdateableObjectHandler, Client, adminAddClientUser, Indexed } from "libs"
import { CustomDropdown, LoaderButton, useBatchProcessing } from "components"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { MdAdd } from "react-icons/md"
import "./UserInfoView.css"

const USER_ROLES = ["admin", "brandEditor", "recruiter"]

export function NewUserInfo({
	clientId,
	client,
	setUserData,
	users,
}: {
	users: Indexed<UserFromDb>
	clientId: string
	client: Client
	setUserData: (newData: UserFromDb) => void
}): JSX.Element {
	const [newUsers, handleNewUsers] = useUpdateableObjectHandler<
		Array<Partial<Pick<UserFromDb, "name" | "email" | "userRole">>>
	>([{}], true)
	const [isRunning, runInBatches] = useBatchProcessing()
	const history = useHistory()
	const [addedUsers, setAddedUsers] = useState<string[]>([])
	const [failedUsers, setFailedUsers] = useState<string[]>([])
	const toAdd = newUsers.filter((newUser) => newUser.email && newUser.name)

	const onFileUpload = (e) => {
		const reader = new FileReader()
		reader.onload = (event) => {
			const file = event.target.result as string
			const allLines = file.split(/\r\n|\n/)
			// Reading line by line
			let writeIndex = 0
			let emailsAdded = []
			let ignoredEmails = []
			allLines.forEach((line, index) => {
				const [name, email, userRole] = line.split(",")
				if (name == null || name?.length === 0 || email == null || email?.length === 0) {
					return
				}
				if (
					email != null &&
					(emailsAdded.includes(email) || Object.values(users).find((user) => user.email === email))
				) {
					ignoredEmails.push(email)
					return
				}
				if (name?.toLowerCase() === "name" || email?.toLowerCase() === "email" || userRole?.toLowerCase() === "role") {
					return
				}
				handleNewUsers(writeIndex)({
					target: {
						// @ts-ignore
						value: {
							name,
							email,
							userRole: USER_ROLES.includes(userRole) ? userRole : "recruiter",
						},
					},
				})
				emailsAdded.push(email)
				writeIndex += 1
			})

			if (ignoredEmails.length > 0) {
				setTimeout(() => alert(`Ignored repeated users: ${ignoredEmails.join(",\n")}`))
			}
		}
		reader.onerror = (event) => {
			alert(event.target.error.name)
		}
		reader.readAsText(e.target.files[0])
	}

	const createUsers = async () => {
		setAddedUsers([])
		setFailedUsers([])
		if (toAdd.length === 0) {
			return
		}
		const indices = toAdd.map((_, index) => index)
		await runInBatches(
			async (index) => {
				const newUser = toAdd[index]
				await createUser(newUser as UserFromDb)
			},
			indices,
			3
		)
		if (failedUsers.length > 0) {
			alert(`Failed to add users: ${failedUsers.join(",\n")}`)
		} else {
			alert("All users added successfully")
			history.replace({
				search: toAdd.length === 1 && addedUsers.length === 1 ? `userId=${addedUsers[0]}` : "",
			})
		}
	}

	const createUser = async (newUser: UserFromDb) => {
		try {
			const newUserFromDb = await adminAddClientUser({
				clientId,
				email: newUser.email,
				name: newUser.name,
				userRole: newUser.userRole,
				clientName: client.name,
			})
			if (newUserFromDb.userId == null) {
				newUserFromDb.userId = newUserFromDb.sk.slice(4)
			}
			if (newUserFromDb.email == null) {
				newUserFromDb.email = newUserFromDb.miscData.slice(6)
			}
			setUserData(newUserFromDb)
			setAddedUsers([...addedUsers, newUserFromDb.userId || newUserFromDb.user_id])
			await timer(400)
		} catch (e) {
			setFailedUsers([...failedUsers, newUser.email])
		}
	}

	return (
		<div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
			{newUsers.map((newUser, index) => (
				<div style={{ display: "flex", gap: 15, alignItems: "center" }}>
					<div>
						<input
							style={{ width: 200 }}
							placeholder="Name"
							type="text"
							value={newUser.name ?? ""}
							onChange={(e) => {
								handleNewUsers(index, "name")(e)
							}}></input>
					</div>
					<div>
						<input
							style={{ width: 300 }}
							placeholder="Email"
							type="text"
							value={newUser.email ?? ""}
							onChange={handleNewUsers(index, "email")}></input>
					</div>
					<CustomDropdown
						value={newUser.userRole ?? "recruiter"}
						bestOption="recruiter"
						actions={[
							{ label: "Admin", key: "admin" },
							{ label: "Recruiter", key: "recruiter" },
							{ label: "Brand Editor", key: "brandEditor" },
						]}
						onChange={handleNewUsers(index, "userRole")}
					/>
					{newUsers.length === index + 1 && (
						<MdAdd
							size={30}
							className="clickable"
							// @ts-ignore
							onClick={() => handleNewUsers(newUsers.length)({ target: { value: {} } })}
						/>
					)}
				</div>
			))}
			<LoaderButton style={{ margin: 5 }} onClick={createUsers} isLoading={isRunning} className="SquarerLoaderButton">
				Create Users{" "}
				{isRunning && (
					<span style={{ marginLeft: 5 }}>
						{addedUsers.length}/{toAdd.length}
					</span>
				)}
			</LoaderButton>
			{toAdd.length === 0 && (
				<>
					<label
						htmlFor="user_csv_upload"
						style={{
							position: "absolute",
							left: 250,
							top: 15,
							minHeight: 20,
							minWidth: 120,
							fontSize: 14,
							width: 120,
							padding: 7,
						}}
						className="LoaderButton SquarerLoaderButton clickable">
						Load From CSV
					</label>
					<input type="file" id="user_csv_upload" style={{ display: "none" }} accept=".csv" onChange={onFileUpload} />
				</>
			)}
		</div>
	)
}

const timer = (ms: number): Promise<void> => new Promise((res) => setTimeout(res, ms))
