function click(node) {
	try {
		node.dispatchEvent(new MouseEvent("click"))
	} catch (e) {
		var evt = document.createEvent("MouseEvents")
		evt.initMouseEvent("click", true, true, window, 0, 0, 0, 80, 20, false, false, false, false, 0, null)
		node.dispatchEvent(evt)
	}
}

export function download(blob: Blob, name: string) {
	var a = document.createElement("a")

	a.download = name
	a.rel = "noopener" // tabnabbing
	a.href = URL.createObjectURL(blob)
	setTimeout(function () {
		URL.revokeObjectURL(a.href)
	}, 4e4) // 40s
	setTimeout(function () {
		click(a)
	}, 0)
}

export function base64ToArrayBuffer(base64: string) {
	var binary_string = window.atob(base64)
	var len = binary_string.length
	var bytes = new Uint8Array(len)
	for (var i = 0; i < len; i++) {
		bytes[i] = binary_string.charCodeAt(i)
	}
	return bytes.buffer
}
