import { useCallback, useState, useRef, useMemo } from "react"
import { usePopper } from "react-popper"
import { createPortal } from "react-dom"
import { FontColorPicker } from "./ColourPicker"
import { useClickOutside, useMountEffect } from "libs"

export const ApplyAfterColourDropdown = ({ color, onChange, disabled = false, align = "left" }) => {
	const [isOpen, toggle] = useState(false)
	const opacity = disabled ? 0.7 : 1
	const cursor = disabled ? "not-allowed" : "pointer"
	const [localColor, setLocalColorBase] = useState(color)
	const colorRef = useRef(localColor) // need to store reference for dismount
	const setLocalColor = useCallback((newColor) => {
		setLocalColorBase(newColor)
		colorRef.current = newColor
	}, [])
	const [titleElement, setTitleElement] = useState(null)
	const [dropElement, setDropElement] = useState(null)
	const placement = "bottom-end"

	const { styles, attributes, update } = usePopper(titleElement, dropElement, {
		placement,
		strategy: "absolute",
		modifiers: [
			{
				name: "preventOverflow",
				options: {
					mainAxis: true,
				},
			},
		],
	})

	useMountEffect(() => () => {
		if (color !== colorRef.current) {
			close()
		}
	}) // set color on dismount if needed

	useMemo(() => {
		setLocalColor(color)
	}, [color, setLocalColor])

	const close = useCallback(() => {
		if (isOpen) {
			onChange(colorRef.current)
			toggle(false)
		}
	}, [isOpen, onChange])
	const pickerRef = useClickOutside(close)

	useMemo(() => {
		if (isOpen) {
			update()
		}
	}, [isOpen, update])

	return (
		<div className="swatch-picker">
			<div
				// title="swatch"
				ref={setTitleElement}
				className="swatch"
				style={{ backgroundColor: localColor, opacity, cursor, position: "relative" }}
				onClick={() => {
					toggle(true)
				}}
			/>
			{createPortal(
				<div
					style={{ ...styles.popper }}
					className={`font-popover align-${align ?? "left"}`}
					ref={(el) => {
						pickerRef.current = el
						setDropElement(el)
					}}
					{...attributes.popper}>
					{isOpen && !disabled && <FontColorPicker currentValue={localColor} setFormat={setLocalColor} />}
				</div>,
				document.body
			)}
		</div>
	)
}
