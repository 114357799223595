import "./JobFormField.css"
import Dropdown from "react-bootstrap/Dropdown"
import "./JobFormField.css"
import { FormField, FormFieldProps } from "./FormField"
import { LoadingSpinner } from "../LoaderButton/LoaderButton"
import { CSSProperties, ReactNode } from "react"

interface DropdownFormFieldProps extends FormFieldProps {
	field: string
	selection?: ReactNode
	options: { text: ReactNode; key?: string; onClick: (arg0?: any) => void }[]
	header?: string
	isLoading?: boolean
	dropdownStyle?: CSSProperties
	dropdownToggleStyle?: CSSProperties
	autoFocus?: boolean
}

export function DropdownFormField({
	autoFocus = false,
	field,
	selection,
	options,
	header,
	isLoading = false,
	dropdownStyle,
	dropdownToggleStyle,
	...props
}: DropdownFormFieldProps) {
	const control = (
		<Dropdown className="userFolderDropdown" style={dropdownStyle}>
			<Dropdown.Toggle
				style={{ minHeight: 38, minWidth: "fit-content", ...dropdownToggleStyle }}
				split
				id="dropdown-item-button"
				variant="aralight"
				className="userFolderDropdownToggle">
				{selection ?? field}
			</Dropdown.Toggle>
			<Dropdown.Menu align="start">
				{header && <Dropdown.Header>{header}</Dropdown.Header>}
				{options.map((option) => (
					<Dropdown.Item key={option.key ?? option.text.toString()} onClick={option.onClick}>
						{option.text}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	)
	return (
		<FormField keyValue={0} rhs={<LoadingSpinner isLoading={isLoading} />} {...props}>
			{control}
		</FormField>
	)
}
