import { useState, useCallback, useMemo } from "react"
import "./FontCasing.css"
import { clientApi, useLoadContext, DEFAULT_FONT_CASE } from "libs"
import { FormField } from "components"
import Dropdown from "react-bootstrap/Dropdown"
import { CasePickerLabel, POSSIBLE_CASE_OPTIONS } from "../CasePicker/CasePicker"

const FONT_CASE_SECTIONS: { jobTitle: string; company: string } = { jobTitle: "Job Title", company: "Company" }

export function FontCaseField({ autoFocus = false, ...props }) {
	const { company, setCompany } = useLoadContext()
	const [currentFontCase, setCurrentFontCase] = useState(company.branding?.fontCase)

	const setNewFontCase = useCallback(
		(sectionName) => (newSelection) => {
			if (company.branding == null) {
				company.branding = {}
			}
			if (company.branding.fontCase == null) {
				company.branding.fontCase = {}
			}
			company.branding.fontCase[sectionName] = newSelection
			clientApi.patch({ clientId: company.id, updates: { branding: company.branding } })
			setCompany({ ...company, branding: { ...company.branding } })
			setCurrentFontCase(newSelection)
		},
		[company, setCompany]
	)

	const makeOptions = useCallback(
		(sectionName) => {
			const optionSet = Object.keys(POSSIBLE_CASE_OPTIONS)
			const currentFontCaseForSection = currentFontCase?.[sectionName] ?? DEFAULT_FONT_CASE
			return optionSet
				.filter((key) => key !== currentFontCaseForSection)
				.map((key) => ({
					text: (
						<CasePickerLabel
							item={POSSIBLE_CASE_OPTIONS[key]}
							currentValue={currentFontCaseForSection}
							setFormat={setNewFontCase(sectionName)}
						/>
					),
					key,
				}))
		},
		[currentFontCase, setNewFontCase]
	)

	const options: [keyof typeof FONT_CASE_SECTIONS, { text: JSX.Element; key: string }[]][] = useMemo(() => {
		return Object.keys(FONT_CASE_SECTIONS).map((section: keyof typeof FONT_CASE_SECTIONS) => [
			section,
			makeOptions(section),
		])
	}, [makeOptions])

	return (
		<>
			{options.map(([name, sectionOptions]) => (
				<FormField
					key={name}
					label={`Select ${FONT_CASE_SECTIONS[name]} Font Case: `}
					keyValue={0}
					controlId={"setFontCase"}
					{...props}>
					<Dropdown className="case-form-field">
						<Dropdown.Toggle split id="dropdown-item-button" variant="aralight">
							{POSSIBLE_CASE_OPTIONS.find((option) => option?.value === currentFontCase?.[name] ?? "none")?.label}
						</Dropdown.Toggle>
						<Dropdown.Menu align="start">
							{<Dropdown.Header>Select Font Case</Dropdown.Header>}
							{sectionOptions.map((option) => (
								<Dropdown.Item key={option.key ?? option.text.toString()}>{option.text}</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				</FormField>
			))}
		</>
	)
}
