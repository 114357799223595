/** Returns current year and month in array */
export function getCurrentYearMonth() {
	//Need to un-zero index month.
	const now = new Date()
	return [now.getFullYear(), now.getMonth() + 1]
}

export function getDDMMYYYYFromDate(date: Date) {
	return `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`
}

/** Converts Unix timestamp to Date object */
export function getDateFromUnix(timestamp) {
	return new Date(timestamp * 1000)
}

const hasLettersRegex = /[a-zA-Z]/gm

/** Use Date.parse functionality, but raise errors correctly, and detect when no months present, Return format dd/mm/yyyy and boolean that is false if months are present in date */
export function parseDDMMYYYYFromDate(date: string): [`${string}/${string}/${string}` | null, boolean] {
	// null returns null
	if (date == null) {
		return [null, false]
	}
	//pure whitespace should return [null, false]
	if (date.trim() === "") {
		return [null, false]
	}
	// at this point we know there is some non-whitespace content
	// 1. check if date has letters in it
	// 2. has letters: use Date.parse
	// 3. no letters: split on non-word chars, if 3 parts then DD/MM/YYYY, if 2 parts then MM/YYYY, if 1 part then YYYY
	if (date.match(hasLettersRegex)?.length > 0) {
		const parsed = new Date(date)
		if (isNaN(parsed.getTime())) {
			throw new Error("Invalid date")
		}
		if (parsed.getMonth() === 0 && !(date.toLowerCase().includes("ja") || date.toLowerCase().includes("en"))) {
			// basic check that january is likely and not just a month not being detcted
			throw new Error("Invalid date")
		}
		let yearToUse = parsed.getFullYear().toString()
		if (parsed.getFullYear() === 2001) {
			// likely to be missing year (Dec 22 parses to 22/12/2001 for example)
			// use the last numbers in the string as the year if possible
			const yearMatch = date.match(/\d{2,4}$/gm)
			if (yearMatch[0] != null) {
				const year = yearMatch[0]
				yearToUse = twoDigitYearToFourDigit(year)
			}
		}
		return [`${parsed.getDate().toString()}/${(parsed.getMonth() + 1).toString()}/${yearToUse}`, false]
	} else {
		const parts = date.split(/\W/gm)
		if (parts.length === 3) {
			if (parts[0].length > 2) {
				// year first
				return [`${parts[2]}/${parts[1]}/${parts[0]}`, false]
			}
			return [`${parts[0]}/${parts[1]}/${twoDigitYearToFourDigit(parts[2])}`, false]
		} else if (parts.length === 2) {
			if (parts[0].length > 2) {
				// year first
				return [`01/${parts[1]}/${parts[0]}`, false]
			}
			return [`01/${parts[0]}/${twoDigitYearToFourDigit(parts[1])}`, false]
		} else if (parts.length === 1) {
			return [`01/01/${twoDigitYearToFourDigit(parts[0])}`, true]
		} else {
			throw new Error("Invalid date")
		}
	}
}

function twoDigitYearToFourDigit(year) {
	if (year.length === 4) {
		return year
	} else if (year.length === 2) {
		const yearInt = parseInt(year)
		if (yearInt > 50) {
			return `19${year}`
		} else {
			return `20${year}`
		}
	} else {
		throw new Error("Invalid date")
	}
}

/** Returns month abbreviation and YYYY from date string in format DD/MM/YYYY */
export function getShortDateFromDDMMYYYY(date, hideMonths = false) {
	const monthConv = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

	if (date) {
		const [, month, year] = date.split("/")
		//month 0 indexed
		return hideMonths ? year : `${monthConv[parseInt(month) - 1] || ""} ${year || ""}`
	} else {
		return ""
	}
}

/** Returns month full name and YYYY from date string in format DD/MM/YYYY */
export function getLongDateFromDDMMYYYY(date, hideMonths = false) {
	const monthConv = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	]

	if (date) {
		const [, month, year] = date.split("/")
		//month 0 indexed
		return hideMonths ? year : `${monthConv[parseInt(month) - 1] || ""} ${year || ""}`
	} else {
		return ""
	}
}

/** Returns month abbreviation and YYYY from date string in format DD/MM/YYYY */
export function getShortSlashedDateFromDDMMYYYY(date, hideMonths = false) {
	if (date) {
		const [, month, year] = date.split("/")
		//month 0 indexed
		return hideMonths ? year : `${month.replace("0", "") || ""}/${year || ""}`
	} else {
		return ""
	}
}

/** Returns month abbreviation and YYYY from date string in format DD/MM/YYYY */
export function getShortZeroSlashedDateFromDDMMYYYY(date, hideMonths = false) {
	if (date) {
		const [, month, year] = date.split("/")
		//month 0 indexed
		return hideMonths ? year : `${month.padStart(2, "0") || ""}/${year || ""}`
	} else {
		return ""
	}
}

export function getYearFromDDMMYYYY(date, hideMonths = false) {
	if (date == null) return ""
	return date.split("/")[2]
}

/** Returns Date object from DD/MM/YYYY date string or default Date object if string is null or empty */
export function getDateFromDDMMYYYY(dateStr: string): Date {
	if (!dateStr) return new Date(0)
	const [day, month, year] = dateStr.split("/")
	const date = new Date(Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day)))
	date.setUTCHours(0, 0, 0, 0)
	return date
}

/** Splits two DD/MM/YYYY date strings into Year, Month & Day components. In descending order, returns differnce between only one of Year, Month or Day. */
export function sortDDMMYYYY(dateStr1, dateStr2) {
	const [day1, month1, year1] = (dateStr1 || "0/0/0").split("/")
	const [day2, month2, year2] = (dateStr2 || "0/0/0").split("/")
	return year2 - year1 || month2 - month1 || day2 - day1
}

/** Returns difference in years, rounded to nearest year, between two date strings in DD/MM/YYYY format */
export function getDiffInYears(dateStr1, dateStr2) {
	const date1 = getDateFromDDMMYYYY(dateStr1).getTime()
	const date2 = getDateFromDDMMYYYY(dateStr2).getTime()
	return Math.round((date2 - date1) / 60 / 60 / 24 / 365240)
}

/** Checks if Unix timestamp parameter is within N minutes ago by comparing it with current Unix timestamp. Presumes 'n' is 5 minutes if not specified */
export function unixstampLessThanNMinutesAgo(stamp, n = 5) {
	const now = new Date().getTime() / 1000
	return now < stamp + n * 60 && now >= stamp
}

/** Converts given number of seconds to years, days, hours, minutes, up to max term (presumed 2). Returns 'zeroMessage' if output is less then 1 minute */
export function secondsToString(seconds, maxTerms = 2, zeroMessage = "under 1 minute") {
	const partitions = [
		Math.floor(seconds / 31536000),
		Math.floor((seconds % 31536000) / 86400),
		Math.floor(((seconds % 31536000) % 86400) / 3600),
		Math.floor((((seconds % 31536000) % 86400) % 3600) / 60),
	]
	const units = ["year", "day", "hour", "minute"]
	var terms = 0
	var stringOut = ""
	for (var i = 0; i < partitions.length; i++) {
		if (partitions[i] !== 0) {
			if (stringOut !== "") stringOut += " "
			stringOut += `${partitions[i]} ${units[i]}${partitions[i] === 1 ? "" : "s"}`
			terms += 1
			if (terms === maxTerms) {
				break
			}
		}
	}
	if (terms === 0) {
		// Assume age is less than 1 minute
		stringOut = zeroMessage
	}
	return stringOut
}

export function getHumanReadableAge(timestamp, maxTerms = 2) {
	const now = new Date().getTime() / 1000
	const age = now - timestamp
	return secondsToString(age, maxTerms)
}

/** Calls secondsToString and returns time left until specified Unix timestamp (param). Returns 'expired' if time has passed or is < 1 minute.  */
export function getHumanReadableExpiry(timestamp, maxTerms) {
	const now = new Date().getTime() / 1000
	const timeLeft = Math.max(0, timestamp - now)
	return secondsToString(timeLeft, maxTerms, "expired")
}

function getWorkingDaysSoFarThisMonth() {
	const now = new Date()
	const daysPassed = now.getDate()
	let workDaysPassed = daysPassed
	for (var i = 1; i <= daysPassed; i++) {
		const day = new Date(now.getFullYear(), now.getMonth(), i)
		if (day.getDay() === 0 || day.getDay() === 6) {
			// weekend
			workDaysPassed -= 1
		}
	}
	return workDaysPassed
}

function getWorkingDaysForAllThisMonth() {
	const now = new Date()
	const daysInMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate()
	let workDays = daysInMonth
	for (var i = 1; i <= daysInMonth; i++) {
		const day = new Date(now.getFullYear(), now.getMonth(), i)
		if (day.getDay() === 0 || day.getDay() === 6) {
			// weekend
			workDays -= 1
		}
	}
	return workDays
}

export function getWorkingDayProportionThisMonth() {
	return getWorkingDaysSoFarThisMonth() / getWorkingDaysForAllThisMonth()
}

/** Returns day, month abbreviation and YYYY from date string in format DD/MM/YYYY */
export function getShortfullDateFromDDMMYYYY(date) {
	const monthConv = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

	if (date) {
		const [day, month, year] = date.split("/")
		//month 0 indexed
		return `${day} ${monthConv[parseInt(month) - 1] || ""} ${year || ""}`
	} else {
		return ""
	}
}

/** Returns month full name and YYYY from date string in format DD/MM/YYYY */
export function getLongFullDateFromDDMMYYYY(date) {
	const monthConv = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	]

	if (date) {
		const [day, month, year] = date.split("/")
		//month 0 indexed
		return `${day} ${monthConv[parseInt(month) - 1] || ""} ${year || ""}`
	} else {
		return ""
	}
}

/** Returns month abbreviation and YYYY from date string in format DD/MM/YYYY */
export function getShortFullSlashedDateFromDDMMYYYY(date) {
	if (date) {
		const [day, month, year] = date.split("/")
		//month 0 indexed
		return `${day || ""}/${month || ""}/${year || ""}`
	} else {
		return ""
	}
}

/** Returns month abbreviation and YYYY from date string in format DD/MM/YYYY */
export function getShortAmericanFullSlashedDateFromDDMMYYYY(date) {
	if (date) {
		const [day, month, year] = date.split("/")
		//month 0 indexed
		return `${month || ""}/${day || ""}/${year || ""}`
	} else {
		return ""
	}
}
