import { createContext, useContext, useState, useRef, useMemo, useCallback, memo } from "react"
import { SelectionContextProps, CurrentSelection } from "./SelectionContext.types"
import Col from "react-bootstrap/Col"
import { LoadingSpinner } from "../LoaderButton/LoaderButton"
import "./ActionButton.css"
import { ActionIcon } from "../Icons"

const SelectionContext = createContext<SelectionContextProps>({ currentSelection: {} })

export const useSelectionContext = () => useContext(SelectionContext)

export const SelectionContextProvider = ({ ids, children }: { ids: string[]; children: React.ReactNode }) => {
	const [currentSelection, setCurrentSelectionBase] = useState<CurrentSelection>({})
	const currentSelectionRef = useRef<CurrentSelection>({})

	const setCurrentSelection = useCallback((newSelection) => {
		setCurrentSelectionBase(newSelection)
		currentSelectionRef.current = newSelection
	}, [])

	useMemo(() => {
		setCurrentSelection(
			Object.entries(currentSelectionRef.current).reduce((selectionOut, [id, isSelected]) => {
				if (ids.includes(id)) {
					selectionOut[id] = isSelected
				}
				return selectionOut
			}, {})
		)
	}, [ids, setCurrentSelection])

	const changeSelectionForId = useCallback(
		(id) => {
			currentSelectionRef.current[id] = !currentSelectionRef.current[id]
			if (id === "all") {
				for (var thisId of ids) {
					currentSelectionRef.current[thisId] = currentSelectionRef.current[id]
				}
			} else if (!currentSelectionRef.current[id]) {
				currentSelectionRef.current.all = false
			}
			setCurrentSelection({ ...currentSelectionRef.current })
		},
		[ids, setCurrentSelection]
	)

	const selectedCount = useMemo(
		() => Object.values(currentSelection).reduce((a, b) => a + Number(b), 0) - (currentSelection.all ? 1 : 0),
		[currentSelection]
	)

	const clearSelection = useCallback(() => {
		setCurrentSelection({})
	}, [setCurrentSelection])

	const selectedIds = useMemo(() => getSelectedIds(currentSelection), [currentSelection])

	return (
		<SelectionContext.Provider
			value={{ selectedIds, currentSelection, changeSelectionForId, selectedCount, clearSelection }}>
			{children}
		</SelectionContext.Provider>
	)
}

function getSelectedIds(currentSelection) {
	return Object.entries(currentSelection ?? {})
		.map(([id, selected]) => (selected ? id : null))
		.filter((id) => id !== "all" && id != null)
}

export const SelectorInput = memo(
	({
		rowId,
		isSelected,
		changeSelectionForId,
	}: {
		rowId: string
		isSelected: boolean
		changeSelectionForId: (id: string) => void
	}) => (
		<input
			className="clickable"
			tabIndex={0}
			type="checkbox"
			checked={!!isSelected}
			value={!!isSelected ? "off" : "on"}
			onChange={(e) => {
				e.stopPropagation()
				changeSelectionForId(rowId)
			}}></input>
	)
)

const ACTIONS = {
	delete: { label: "Delete" },
	duplicate: { label: "Duplicate" },
	close: { label: "Close" },
	changeOwner: { label: "Change Owner" },
}

export function ActionButton({ isLoading, onClick, action, disabled }) {
	return (
		<Col xs={12} sm={4} md="auto">
			<div
				className={`action-button ${disabled ? "action-button-disabled" : "clickable"}`}
				role="button"
				onClick={onClick}>
				<ActionIcon action={action} size={24} color="black" />
				<div className="action-text">{ACTIONS[action].label}</div>
				<LoadingSpinner className="action-spinner" isLoading={isLoading} />
			</div>
		</Col>
	)
}

function* batchArray(array, batchSize) {
	const arrayCopy = [...array]
	while (arrayCopy.length > 0) {
		yield arrayCopy.splice(0, batchSize)
	}
}

const BATCH_SIZE = 10

export const useBatchProcessing = () => {
	const [isRunning, setIsRunning] = useState(false)
	const runInBatches = useCallback(
		async (func: (id) => Promise<void>, ids: Array<number | string>, batchSize: number = BATCH_SIZE) => {
			if (isRunning) {
				console.log("already running batch processing")
				return
			}
			setIsRunning(true)
			const failures = {}
			for (var batch of batchArray(ids, batchSize ?? BATCH_SIZE)) {
				await Promise.all(
					batch.map((id) =>
						func(id).catch((e) => {
							failures[id] = e.message ?? e.toString()
						})
					)
				)
			}
			setIsRunning(false)
			return failures
		},
		[isRunning]
	)

	return [isRunning, runInBatches] as const
}
