import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Routes from "./Routes"
import { CombinedUser, ConfigProvider, FeaturesProvider, User, useLogo, useMountEffect } from "libs"
import { AppContextProvider } from "libs"
import { Auth } from "@aws-amplify/auth"
import { onError, useLogin } from "libs"
import { AppSkeleton } from "components"
// import CookieConsent from 'react-cookie-consent'
import { config } from "./config"

let nullCompany = { name: null, id: null }

function App() {
	const [user, setUser] = useState<CombinedUser>({ isAuthenticated: false, name: "" } as CombinedUser)
	const [company, setCompany] = useState(nullCompany)
	const performLogin = useLogin({
		responseOnly: true,
		setCompanyOverride: setCompany,
		setUserOverride: setUser,
	})
	const history = useHistory()
	const [isAuthenticating, setIsAuthenticating] = useState(true)
	// const {width} = useWindowDimensions({includeHeight: false})
	const { logo, setLogoAndSendToDb, fetchLogoFromDb } = useLogo()
	const navbarLinks = {
		Accounts: "/accounts",
		"Add Client": "/addclient",
		"Default Templates": "/defaulttemplates",
	}
	if (user.role === "admin") {
		navbarLinks["Settings"] = "/settings"
	}

	async function onLoad() {
		try {
			//const responseS = await Auth.currentSession();
			const response = await Auth.currentUserInfo()
			if (response !== null && response !== undefined) {
				// setUser(makeUser(response,{}))
				await performLogin({ response })
			}
		} catch (e) {
			if (e !== "No current user") {
				onError(e)
			}
		}
	}

	useMountEffect(async () => {
		await onLoad()
		setIsAuthenticating(false)
	})

	useEffect(() => {
		if (logo == null) {
			fetchLogoFromDb(company)
		}
	}, [company])

	async function handleLogout() {
		await Auth.signOut()
		setUser({ isAuthenticated: false, name: "" } as CombinedUser)
		setCompany(nullCompany)
		history.push("/login")
	}

	function logoLink() {
		return user.isAuthenticated ? config.homepage : "/"
	}

	return (
		<ConfigProvider config={config}>
			<FeaturesProvider company={company} user={user}>
				<AppSkeleton
					navbar={user.isAuthenticated}
					isAuthenticating={isAuthenticating}
					logoLink={logoLink()}
					navbarLinks={navbarLinks}
					userName={user.name}
					logo={logo}
					handleLogout={handleLogout}>
					<AppContextProvider
						value={{ user, setUser, company, setCompany }}
						logoValue={{ logo, setLogoAndSendToDb, fetchLogoFromDb }}>
						<Routes />
					</AppContextProvider>
				</AppSkeleton>
			</FeaturesProvider>
		</ConfigProvider>
	)
}

export default App
