/* eslint-disable react/prop-types */
import { Auth } from "@aws-amplify/auth"
import { useMountEffect } from "libs"
import QRCode from "react-qr-code"
import { LoadingSpinner } from "../LoaderButton/LoaderButton"
import { useState } from "react"
// import Form from "react-bootstrap/Form"
// import { LoginFormField } from "../LoginFormField/LoginFormField"
// import { CenteredLoaderButton } from "../LoaderButton/LoaderButton"
import { SignUpForm } from "../SignUpComponents/SignUpForm/SignUpForm/SignUpForm"
// import { SignUpCard } from "../SignUpComponents/SignUpCard/SignUpCard"
import { SignUpCardWithImage } from "../SignUpComponents/SignUpCardWithImage"

export function MFASetup({ signinResponse, onSubmit, fields, handleFieldChange, mfaRef, sessionOverrideRef }) {
	const [strForCode, setStrForCode] = useState(() => "")
	const [isLoading, setLoading] = useState(false)

	useMountEffect(() => {
		Auth.setupTOTP(signinResponse).then((code) => {
			console.log(signinResponse)
			console.log(code)
			setStrForCode("otpauth://totp/AWSCognito:" + signinResponse.username + "?secret=" + code + "&issuer=ARAAdminSite")
		})
	})

	async function onSubmitInt(e) {
		try {
			setLoading(true)
			e.preventDefault()
			console.log("verifying token")
			const verifyResponse = await Auth.verifyTotpToken(signinResponse, fields.code)
			sessionOverrideRef.current = verifyResponse
			await Auth.setPreferredMFA(signinResponse, "TOTP")
			// console.log('confirming signin')
			// await Auth.confirmSignIn(signinResponse, fields.code, 'SOFTWARE_TOKEN_MFA');
			mfaRef.current = true
			await onSubmit?.(e)
		} catch (e) {
			alert(e)
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
			<MFAWrap
				size="large"
				fields={fields}
				handleFieldChange={handleFieldChange}
				onSubmit={onSubmitInt}
				isLoading={isLoading}
				isValid={fields.code.length === 6}>
				{strForCode != null ? (
					<div style={{ textAlign: "center" }}>
						<h2>Please scan the QR code from an authenticator application (eg Google Authenticator)</h2>
						<QRCode style={{ margin: "2rem 0 1rem 0" }} value={strForCode} />
						<hr className="mfa-form-divider" />
						{/* <Form.Text>{`(Alternatively you may set up the autenticator by entering the following code in the authenticator application: ${strForCode})`}</Form.Text> */}
					</div>
				) : (
					<LoadingSpinner isLoading={true} />
				)}
			</MFAWrap>
		</>
	)
}

export function MFAValidate({ signinResponse, fields, handleFieldChange, onSubmit, mfaRef }) {
	const [isLoading, setLoading] = useState(false)

	async function onSubmitInt(e) {
		try {
			setLoading(true)
			e.preventDefault()
			console.log("confirming signin", signinResponse)
			const confirmResponse = await Auth.confirmSignIn(signinResponse, fields.code, "SOFTWARE_TOKEN_MFA")
			console.log("signin confirmed", confirmResponse)
			mfaRef.current = true
			await onSubmit(e)
		} catch (e) {
			alert(e)
		} finally {
			setLoading(false)
		}
	}

	return (
		<MFAWrap
			size="medium"
			fields={fields}
			handleFieldChange={handleFieldChange}
			onSubmit={onSubmitInt}
			isLoading={isLoading}
			isValid={fields.code.length === 6}
		/>
	)
}

// function MFACodeEntry({ fields, handleFieldChange }) {
// 	return (
// 		<>
// 			<Form.Text>Please enter the 6 digit code shown in your MFA application</Form.Text>
// 			<LoginFormField
// 				label="MFA Code"
// 				tooltipProps={{
// 					tooltipLabel: "Must be a 6 digit code",
// 					highlight: fields.code?.length === 6,
// 					holdOn: fields.code?.length > 0 && fields.code?.length >= 6,
// 				}}
// 				controlId="code"
// 				type="text"
// 				field={fields.code}
// 				fieldHandler={handleFieldChange}></LoginFormField>
// 		</>
// 	)
// }

function MFAWrap({ fields, handleFieldChange, onSubmit, children = undefined, isValid, isLoading, size }) {
	return (
		<>
			<SignUpCardWithImage size={size}>
				<div className={`mfa-form-container mfa-form-container-${size}`}>
					{children}
					<SignUpForm
						title={
							<>
								<h2>Please enter the 6 digit code shown in your MFA application</h2>
							</>
						}
						button={{
							isLoading: isLoading,
							text: "Continue",
							role: "submit",
							onClick: onSubmit,
							disabled: !isValid,
						}}
						fields={[
							{
								label: "MFA Code",
								type: "text",
								placeholder: "Enter MFA Code",
								fieldName: "code",
								initialValue: fields.code,
								autofocus: true,

								required: true,
								inputFieldHandler: handleFieldChange,
								description: (
									<span style={fields.code?.length === 6 ? { display: "none" } : { color: "var(--ara-error)" }}>
										Must be a 6 digit code
									</span>
								),
							},
						]}
					/>
				</div>
			</SignUpCardWithImage>
			{/* // <SignUpCard image={<></>} imageContainerStyle={{ display: "none" }} logo>
			// 	{children}
			// </SignUpCard> */}
			{/* <form onSubmit={onSubmit}>
				{children}
				<CenteredLoaderButton type="submit" size="large" isLoading={isLoading} disabled={!isValid}>
					Submit
				</CenteredLoaderButton>
			</form> */}
		</>
	)
}
