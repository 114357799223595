import { useLocation } from "react-router-dom"
import { FieldsetProps, NavbarLink, CurrentLink } from "./adminLib.types"

const getYear = () => new Date().getFullYear()
const getMonth = () => new Date().getMonth()

const fieldSet: FieldsetProps = {
	cvPerMonth: [
		{
			key: 30,
			label: "30",
		},
		{
			key: 50,
			label: "50",
		},
		{
			key: 100,
			label: "100",
		},
		{
			key: 150,
			label: "150",
		},
		{
			key: 200,
			label: "200",
		},
		{
			key: 300,
			label: "300",
		},
		{
			key: 400,
			label: "400",
		},
		{
			key: 500,
			label: "500",
		},
		{
			key: 700,
			label: "700",
		},
		{
			key: 1000,
			label: "1000",
		},
		{
			key: "1000+",
			label: "1000+",
		},
	],
	paymentType: [
		{
			key: "monthly",
			label: "Monthly",
		},
		{
			key: "quarterly",
			label: "Quarterly",
		},
		{
			key: "annually",
			label: "Annual",
		},
	],
	miscData: [
		{
			key: "free_trial",
			label: "Free Trial",
			color: "#FF6B00",
		},
		{
			key: "lost",
			label: "Lost",
			color: "#FF0101",
		},
		{
			key: "test",
			label: "Test",
			color: "#FF6B00",
		},
		{
			key: "demo",
			label: "Demo",
			color: "#FF6B00",
		},
		{
			key: "paid",
			label: "Paid",
			color: "#00C853",
		},
		{
			key: "paused",
			label: "Paused",
			color: "#FF6B00",
		},
	],
}

const navbarLink: NavbarLink = {
	account: {
		title: "Account",
		path: "account",
	},
	templates: {
		title: "Templates",
		path: "templates",
	},
	team: {
		title: "Team",
		path: "team",
	},
	volume: {
		title: "Volume",
		path: "volume",
	},
	options: {
		title: "Options",
		path: "options",
	},
	integrations: {
		title: "Integrations",
		path: "integrations",
	},
	tags: {
		title: "Tags",
		path: "tags",
	},
	analytics: {
		title: "Analytics",
		path: "analytics",
	},
	login: {
		title: "Login",
		path: "login",
	},
	delete: {
		title: "Delete",
		path: "delete",
		allowedUsers: ["admin"],
	},
}

function useLocationByPath(): { clientId: string; currentLink: CurrentLink } {
	const location = useLocation()
	const [, , clientId, currentLocation] = location.pathname.split("/")
	const currentLink = navbarLink[currentLocation]?.path

	return {
		clientId,
		currentLink,
	}
}

function getCurrentStatus(status: string) {
	if (!status) {
		return null
	}
	const firstPart = status.split("#")[0]
	if (firstPart !== "client_status") {
		// override status avail to monthly as default
		return status
		// test:
		// const statusOne = 'avail#-1'
		// const statusTwo = 'client_status#annual'
	}
	const item = status.split("#")[1] ?? "-"
	if (item) {
		return item
	}
}

function getCurrentMonth(stats) {
	if (!stats) {
		return 0
	}
	const currentMonth = stats[getYear()]?.[getMonth() + 1] ?? 0
	return currentMonth?.cvs_read ?? 0
}

function getPreviousMonth(stats, offset = 1) {
	if (!stats) {
		return 0
	}
	const prevMonth = getMonth() + 1 - offset
	const currentMonth =
		stats[prevMonth <= 0 ? getYear() - 1 : getYear()]?.[prevMonth <= 0 ? 12 - prevMonth : prevMonth] ?? 0
	return currentMonth?.cvs_read ?? 0
}

function getCurrentYear(stats) {
	if (!stats) {
		return 0
	}
	const currentYear = stats[getYear()] ?? 0
	let counter = 0
	for (let month in currentYear) {
		counter += currentYear[month]?.cvs_read ?? 0
	}
	return counter
}

function getCountUsers(users) {
	if (!users) {
		return "0/0"
	}

	let countUser = 0
	let countActive = 0
	for (let user in users) {
		countUser++
		if (users[user].lastSignIn) {
			countActive++
		}
	}

	return `${countActive}/${countUser}`
}

function weeklyActiveUsers(users) {
	if (!users) {
		return 0
	}
	let count = 0
	for (let user in users) {
		if (users[user].lastSignIn > Date.now() - 7 * 86400000) {
			count++
		}
	}
	return count
}

/**
 * It takes two strings, converts them to numbers, and returns the difference between the two
 * @param {string} key_one - The first key to compare.
 * @param {string} key_two - The value of the key in the object that you want to sort by.
 * @returns The difference between the two numbers.
 */
function sortBy(key_one: string, key_two: string) {
	let key_one_value = Number(key_one) || 0
	let key_two_value = Number(key_two) || 0

	return key_two_value - key_one_value
}

export {
	navbarLink,
	useLocationByPath,
	fieldSet,
	getCurrentStatus,
	getCurrentMonth,
	getPreviousMonth,
	getCurrentYear,
	getCountUsers,
	weeklyActiveUsers,
	sortBy,
}
