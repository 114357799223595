import { useState } from "react"
import { Client, clientApi, useLoadContext } from "libs"
import { useMountEffect } from "libs"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { getCurrentYearMonth } from "libs"
import { VolumeCard } from "./VolumeCard"

export function VolumeTab() {
	const { company } = useLoadContext()
	var [stats, setStats] = useState<Client["stats"]>({} as Client["stats"])
	const [year, month] = getCurrentYearMonth()

	useMountEffect(() => {
		//set stats using current company details,then make API call to update
		setStats(company.stats)
		clientApi.get({ clientId: company.id }).then((response) => {
			setStats((response as Client).stats)
		})
	})

	return (
		<>
			<Col>
				<Row className="volumeHeading">{"This month:"}</Row>
				<Row style={{ justifyContent: "start", padding: "0 3px" }}>
					<Col className="col-form-label" xs={3}></Col> {/* spacing */}
					<Col xs={4}>
						<VolumeCard number={stats?.all?.[year]?.[month]?.cvs_read ?? 0} label="CVs read" />
					</Col>
				</Row>
				<Row className="volumeHeading">{"This year:"}</Row>
				<Row style={{ justifyContent: "start", padding: "0 3px" }}>
					<Col className="col-form-label" xs={3}></Col> {/* spacing */}
					<Col xs={4}>
						<VolumeCard
							number={Object.values(stats?.all?.[year] ?? {}).reduce((a, b) => a + (b?.cvs_read ?? 0), 0)}
							label="CVs read"
						/>
					</Col>
				</Row>
			</Col>
		</>
	)
}
