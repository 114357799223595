import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import "./JobFormField.css"
import { AraTooltip, AraTooltipProps } from "../AraTooltip/AraTooltip"
import { ReactNode, ReactElement, CSSProperties } from "react"

export interface FormFieldProps {
	label: ReactNode
	tooltipProps?: AraTooltipProps
	controlId?: string
	keyValue?: number | string
	rhs?: ReactNode
	colWidths?: number[]
	labelStyle?: CSSProperties
	style?: CSSProperties
	children?: ReactElement
}

export function FormField({
	label,
	tooltipProps = {},
	controlId,
	keyValue,
	rhs,
	colWidths = [5, 5, 2],
	labelStyle = {},
	...props
}: FormFieldProps) {
	return (
		<Row
			as={Form.Group}
			className="jobFormField standardPadding"
			style={{ alignItems: "center", ...props.style }}
			controlId={controlId}
			key={keyValue}>
			<Form.Label column xs={colWidths[0]} style={labelStyle}>
				{label}{" "}
			</Form.Label>
			<Col xs={colWidths[1]} className="inputCol">
				<AraTooltip {...tooltipProps}>{props.children}</AraTooltip>
			</Col>
			<Col xs={colWidths[2]} className="formField-rhs">
				{" "}
				{rhs}
			</Col>
		</Row>
	)
}
