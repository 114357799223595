import { useHandler } from "libs"
import { CustomDropdown } from "components"

export function VolumePage({ data, clientId }): JSX.Element {
	return (
		<div className="account-container">
			<h1>Volume</h1>
			<StatsDisplay stats={data?.stats?.all} />
		</div>
	)
}

const sortEntriesDescending = ([a]: [string, object], [b]: [string, object]): number => parseInt(b) - parseInt(a)

type StatName = "cvs_read" | "ai_token_usage" | "formattedWord"

interface StatSet {
	[year: string]: { [month: string]: { cvs_read: number; tokens_used: number } }
}

type StatDropdownOption = { key: StatName; label: string }

const STAT_TYPES: StatDropdownOption[] = [
	{ key: "cvs_read", label: "CVs read" },
	{ key: "ai_token_usage", label: "OpenAI Tokens Used" },
	{ key: "formattedWord", label: "Word downloads" },
]

function StatsDisplay({ stats }: { stats: StatSet }) {
	const [statToDisplay, handleStatToDisplay] = useHandler("cvs_read")

	if (stats == null) {
		return <div>No CVs have been uploaded yet</div>
	}
	return (
		<>
			<div style={{ display: "flex", gap: 15, alignItems: "center", justifyContent: "center", marginBottom: 20 }}>
				<div>Statistic: </div>
				<CustomDropdown
					value={statToDisplay}
					actions={STAT_TYPES}
					bestOption={"cvs_read"}
					onChange={handleStatToDisplay}></CustomDropdown>
			</div>
			<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
				{Object.entries(stats)
					.sort(sortEntriesDescending)
					.map(([year, statsForYear]) => {
						return (
							<StatsDisplayForYear key={`year-${year}`} statsForYear={statsForYear} year={year} stat={statToDisplay} />
						)
					})}
			</div>
		</>
	)
}

function StatsDisplayForYear({ year, statsForYear, stat = "cvs_read" }) {
	return (
		<div style={{ paddingBottom: 15, textAlign: "center" }}>
			<h3>{year}</h3>
			{Object.entries(statsForYear)
				.sort(sortEntriesDescending)
				.map(([month, statsForMonth]) => {
					return (
						<StatsDisplayForMonth key={`${year}-${month}`} statsForMonth={statsForMonth} month={month} stat={stat} />
					)
				})}
		</div>
	)
}

function StatsDisplayForMonth({ month, statsForMonth, stat = "cvs_read" }) {
	return (
		<>
			{statsForMonth[stat] != null && (
				<div style={{ display: "flex", flexDirection: "row", gap: 5, justifyContent: "center" }}>
					<div style={{ width: 80, textAlign: "left" }}>{monthMapping[month]}</div>
					<div style={{ width: 20, textAlign: "left" }}>{statsForMonth[stat]}</div>
				</div>
			)}
		</>
	)
}

const monthMapping = [
	"Nothinguary",
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
]
