export const SUPPORTING_FILE_DESIGNATIONS = {
	coverLetter: { label: "Cover Letter" },
	// other: { label: "Other" },
}

export const FILE_DESIGNATIONS = {
	cv: { label: "CV" },
	...SUPPORTING_FILE_DESIGNATIONS,
}

export type FileDesignation = keyof typeof FILE_DESIGNATIONS
