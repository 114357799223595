import { getNormalWeightClass } from "libs"
import { getFontFamily, useLoadContext } from "libs"

export function FontName({ font }) {
	const { company } = useLoadContext()
	return (
		<span className={`${getNormalWeightClass(font, company)}`} style={{ fontFamily: getFontFamily(font, company) }}>
			{font}
		</span>
	)
}
