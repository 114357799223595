import "./Settings.css"
import { capitalize, mapAccessBasedOnPermissions, useConfigContext, useLoadContext } from "libs"
import { LoaderButton, CardView } from "components"
import { useStickyState } from "libs"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { SettingsTab } from "./Components/SettingsTab"
import { AccountTab } from "./Components/AccountTab"
import { TeamTab } from "./Components/TeamTab"
import { VolumeTab } from "./Components/VolumeTab"
import { DataTab } from "./Components/DataTab"
import { DefaultsTab } from "./Components/DefaultsTab"
import { BrandingTab } from "./Components/BrandingTab"

const availableSettingsTabs = {
	account: AccountTab,
	team: TeamTab,
	volume: VolumeTab,
	data: DataTab,
	defaults: DefaultsTab,
	branding: BrandingTab,
}

export function Settings() {
	const tabParams = { colWidths: [4, 7, 1] }
	const { user } = useLoadContext()
	const { accessPermissions } = useConfigContext()
	const config = useConfigContext()
	const settingsAccess =
		config.site === "present"
			? {
					none: ["account", "defaults"],
					read: ["account", "defaults"],
					write: ["account", "team", "volume", "data", "defaults", "branding"],
				}
			: {
					write: ["team"],
					read: ["team"],
					none: ["team"],
				}
	const settingsTabsPerUserType = mapAccessBasedOnPermissions(user.role, "settings", settingsAccess, accessPermissions)
	const [currentTab, setCurrentTab] = useStickyState({
		initState: "account",
		storageName: "settingsTab",
		forceOptions: settingsTabsPerUserType as string[],
	})
	function getTabName(currentTab) {
		return settingsTabsPerUserType.includes(currentTab) ? currentTab : "account"
	}

	function tabButtonClick(tab) {
		return function (event) {
			event.preventDefault()
			setCurrentTab(tab)
			const target = event.currentTarget //need to preserve a reference other than the original
			target.blur()
		}
	}
	function setButtonColor(tab) {
		if (tab === currentTab) {
			return "btn-aragold"
		} else {
			return "btn-aralight"
		}
	}

	function SettingsPage() {
		const tabName = getTabName(currentTab)
		const tabFunction = availableSettingsTabs?.[tabName]
		const content = <SettingsTab title={capitalize(tabName)}>{tabFunction(tabParams)}</SettingsTab>

		return (
			<CardView className="settings-card">
				<Row>
					<Col xs={12} md={3}>
						<Row className="settingsButtonTab">
							{((settingsTabsPerUserType as string[]) ?? []).map((tab) => (
								<Col xs={4} sm md={12} key={tab}>
									<LoaderButton className={setButtonColor(tab)} onClick={tabButtonClick(tab)}>
										{capitalize(tab)}
									</LoaderButton>
								</Col>
							))}
						</Row>
					</Col>
					<Col xs={12} md={9}>
						{content}
					</Col>
				</Row>
			</CardView>
		)
	}

	return <SettingsPage />
}
