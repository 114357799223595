import { CardView, LoaderButton } from "components"
import { adminAddClient } from "libs"
import { useCallback, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"

export function AddClient() {
	const [clientName, setClientName] = useState("")
	const [subdomain, setSubdomain] = useState("")
	const [loading, setLoading] = useState(false)
	const history = useHistory()

	const handleClientNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setClientName(e.target.value)
	}

	const subdomainSuggestion = useCallback(() => {
		if (clientName.length > 0) {
			let parts = clientName.split(" ")
			if (parts[parts.length - 1].length < 4) {
				parts.pop()
			}
			return parts.join("-").toLowerCase()
		} else {
			return ""
		}
	}, [clientName])

	const currentSubdomain = useMemo(() => subdomain || subdomainSuggestion(), [subdomain, subdomainSuggestion])

	const handleSubdomainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSubdomain(e.target.value)
	}

	const handleCreate = async () => {
		setLoading(true)
		try {
			console.log("creating client", clientName, currentSubdomain)
			const newClientId = await adminAddClient({ clientName, clientSubdomain: currentSubdomain })
			history.push(`/client/${newClientId}/login`)
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}
	return (
		<div>
			<CardView style={{ maxWidth: "500px", margin: "100px auto" }}>
				<h3 style={{ textAlign: "center", marginBottom: 15 }}>Add New Client</h3>
				<div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
					<div style={{ display: "flex", flexDirection: "column" }}>
						<label>Client Name: </label>
						<input value={clientName} onChange={handleClientNameChange} type="text"></input>
					</div>
					<div style={{ display: "flex", flexDirection: "column" }}>
						<label>Subdomain: </label>
						<input value={currentSubdomain} onChange={handleSubdomainChange} type="text"></input>
					</div>
					<div style={{ alignSelf: "center" }}>
						<LoaderButton
							disabled={clientName?.length < 1}
							onClick={handleCreate}
							isLoading={loading}
							className="SquarerLoaderButton">
							Create
						</LoaderButton>
					</div>
				</div>
			</CardView>
		</div>
	)
}
