import { UserFromDb, Client, Indexed } from "libs"
import { CardView, CenteredStatus } from "components"
import { useHistory } from "react-router-dom"
import { MdClose } from "react-icons/md"
import "./UserInfoView.css"
import { ExistingUserInfo } from "./ExistingUserInfo"
import { NewUserInfo } from "./NewUserInfo"

export function UserInfoView({
	users,
	clientId,
	client,
	user,
	setUserData,
	newUserMode,
}: {
	users: Indexed<UserFromDb>
	clientId: string
	client: Client
	user: UserFromDb | null
	setUserData: (newData: UserFromDb) => void
	newUserMode: boolean
}): JSX.Element {
	const history = useHistory()
	return user === undefined && !newUserMode ? (
		<CenteredStatus isLoading={true} />
	) : (
		<CardView className="user-info-view-card">
			<div className="user-info-title-row">
				<h2>{newUserMode ? "Add New Users" : user?.name}</h2>
				<MdClose
					size={30}
					className="clickable"
					onClick={() => {
						history.replace({
							search: "",
						})
					}}
				/>
			</div>
			<div className="user-info-view-inner">
				{newUserMode ? (
					<NewUserInfo users={users} clientId={clientId} client={client} setUserData={setUserData}></NewUserInfo>
				) : (
					<ExistingUserInfo user={user} setUserData={setUserData} clientId={clientId} />
				)}
			</div>
		</CardView>
	)
}
